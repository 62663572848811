import React from 'react'

const Tips_keep_asthma_under_control = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(17, 112, 150)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>Tips to Keep Your Asthma Under Control</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/home">Home</a></li>
                    </ul>
                </div>
            </div>
            <div className='container'>
                <div className='row'>
                    <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                        <div className="row">
                            <div className="col-lg-7 mb-2">
                                <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                                    <span style={{ color: "rgb(17, 112, 150)" }}>Tips to Asthma Control</span>
                                </h1>
                                <p style={{ alignItems: 'justify' }}>
                                Asthma is a chronic respiratory condition affecting millions worldwide. It can cause symptoms such as coughing, wheezing, shortness of breath, and chest tightness, making it difficult to breathe. However, with proper management and treatment, asthma can be controlled, and its symptoms can be minimised. In this blog post, we'll share tips to help manage your asthma and breathe easily.                                </p>
                                <p style={{ alignItems: 'justify' }}><b>
                                1.	Know your triggers - </b>The first step in controlling your asthma is to identify your triggers. Triggers are things that can cause your asthma symptoms to flare up, such as pollen, dust mites, animal dander, cold air, or exercise. By knowing your triggers, you can take steps to avoid or minimise exposure to them.
                                </p>

                                <p style={{ alignItems: 'justify' }}><b>
                                2.	Take your medications as prescribed - </b>Medications are an essential part of managing asthma. If medications have been prescribed for your asthma, it's important to take them as directed by your doctor. This may include inhalers, nebulizers, or oral medications. These medications help to control inflammation and open up airways, making it easier to breathe.                                </p>
                            </div>
                            <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                                <img className="mt-5" style={{ height: '300px', width: '80%', borderRadius: '15px' }} id="ServicesImg" src='TIPSTOASTHMA.png' alt=''></img>
                            </div>
                        </div>
                        <p style={{ alignItems: 'justify' }}><b>
                        3.	Follow an Asthma action plan - </b>An asthma action plan is a written plan outlining the steps you should take when your asthma symptoms worsen. Doctors can help create an asthma action plan that includes instructions on when to take your medication, when to seek medical help, and how to manage your triggers.                        </p>
                        <p style={{ alignItems: 'justify' }}><b>
                        4.	Monitor your lung function - </b>Monitoring your lung function is an important part of controlling your asthma. Doctors may recommend using a peak flow metre, which measures how well you can breathe out air. By monitoring your lung function regularly, you can detect changes in your asthma symptoms and take appropriate action.                        </p>
                        <p style={{ alignItems: 'justify' }}><b>
                        5.	Keep your environment clean - </b>Keeping your environment clean is essential for controlling asthma symptoms. This includes reducing exposure to dust, mould, and other allergens. Vacuum regularly, wash bedding in hot water, and use an air purifier.      
                        </p>
                        <p style={{ alignItems: 'justify' }}><b>
                        6.	Stay active - </b>Exercise is a part of a healthy lifestyle, even if you have asthma. Regular exercise can help improve lung function, reduce inflammation, and strengthen respiratory muscles. Talk to a doctor before starting new exercise programs, and take precautions, such as using your inhaler before exercising.                        </p>
                        <p style={{ alignItems: 'justify' }}><b>7.	Manage stress - </b>Stress can exacerbate asthma symptoms, so it's important to manage stress levels. This includes practising relaxation techniques like deep breathing, meditation, or yoga. It's also important to get enough sleep and make time for activities you enjoy.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Tips_keep_asthma_under_control