import React from 'react'

const ALLERGY_MANAGMENT = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(17, 112, 150)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>MANAGEMENT OF COMPLEX RESPIRATORY ALLERGIC DISORDERS & ASTHMA</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/home">Home</a></li>

                    </ul>
                </div>
            </div>

            <div className='container'>
                <div className='row'>
                    <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                        <div className="row">
                            <div className="col-lg-7 mb-2">
                                <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                                    <span style={{ color: "rgb(17, 112, 150)" }}>MANAGEMENT OF COMPLEX RESPIRATORY ALLERGIC DISORDERS & ASTHMA</span>
                                </h1>
                                <p>
                                    Respiratory allergies are triggered by proteins in the air inhaled and prompt airway inflammation. They might be because of specific allergic reactions or general reactions to irritants like smoke and fumes in the indoor and outdoor atmosphere that can worsen allergen symptoms. Pneumonia specialist in Guntur might prescribe or recommend 1 or more of these treatmentsto open up the airways and aid in breathing easier.
                                </p>

                                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                                    <span style={{ color: "rgb(52, 186, 240)" }}>Bronchodilators</span>
                                </h4 >
                                <p style={{ textAlign: 'justify' }}>
                                    Bronchodilators relax muscles around the aviation routes to open them up. You breathe in the medication through a device known as inhaler.
                                </p>

                            </div>
                            <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                                <img className="mt-5" style={{ width: '80%', height: '300px', borderRadius: '15px' }} id="ServicesImg" src='WhatsApp Image 2023-08-05 at 11.54.37 AM (1).jpeg' alt=''></img>
                            </div>
                        </div>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}>Steroids</span>
                        </h4 >
                        <p>Steroids cut down swelling in your aviation routes. Typically you take in steroids through an inhaler.</p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}>Oxygen therapy</span>
                        </h4 >
                        <p>Oxygen treatment by best pneumonia doctors in Guntur gives oxygen to your lungs to help you relax.</p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}>Humidifier</span>
                        </h4 >
                        <p>To assist you with breathing at night, you can turn on a warm fog humidifier.</p>


                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}>Allergy management in Guntur</span>
                        </h4 >
                        <p><b>Pulmonary rehabilitation
                            This is a program to assist you with breathing better. During pulmonary rehab, you'll work with specialists, medical caretakers, and different experts. The program can include:</b></p>
                        <ul>
                            <li>Exercises to enhance breathing</li>
                            <li>Nutrition</li>
                            <li>Methods to aid in save energy</li>
                            <li>Tips to assist in breathing better</li>
                            <li>Counselling and support</li>
                            <li>Breathing methods</li>
                            <li>Breathing methods such as pursed-lip breathing can assist with easing back your breathing rate.</li>
                        </ul>

                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}>Immunizations</span>
                        </h4 >
                        <p><b>Allergic bronchitis can expand your danger for lung diseases. Get the following vaccines which can assist you stay healthy:</b></p>
                        <ul>
                            <li><p>an influenza shot one time per year</p></li>
                            <li><p>a pneumonia shot each five or six years</p></li>
                        </ul>
                        
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}>Asthma</span>
                        </h4 >
                      <p>Asthma is a persistent condition that influence the aviation routes. It causes wheezing and can make it difficult to breathe. A few triggers incorporate openness to an allergen or aggravation, infections, exercise, emotional stress, and different factors. </p>
<h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}> Treatment</span>
                        </h4 >
                        <p>Prevention and long-haul control are key to stop the asthma attacks before they begin. Treatment for Asthma in Guntur typically includes figuring out how to perceive your triggers, taking the steps to prevent triggers and track your breathing to ensure your meds are monitoring manifestations. In the event of an asthma erupt, you might have to utilize a fast-relief inhaler.</p>

                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}>Medications</span>
                        </h4 >
                        <p>The right prescriptions for you is based on various things — your age, indications, asthma triggers and what works best to monitor your asthma.</p>
                        <p>Long haul asthma control meds: These prescriptions monitor asthma on an everyday basis and make it less you'll have an asthma attack.</p>
                        <p><b>Kinds of long haul control medications include:</b></p>
                        <ul>
                            <li>Inhaled corticosteroids.</li>
                            <li>Leukotriene modifiers</li>
                            <li>Combination inhalers.</li>
                            <li>Theophylline.</li>
                            <li>Quick-alleviation (salvage) meds</li>
                            <li>Short-acting beta agonists.</li>
                            <li>Anticholinergic specialists.</li>
                            <li>Oral and intravenous corticosteroids.</li>
                        </ul>
                        <p>Get the treatment for pneumonia, tuberculosis, Covid-19, lung cancer treatment in Guntur from pulmonology doctors in Guntur. We are providing Management of complex respiratory allergic disorders & asthma treatment</p>
                       
                    </div>

                </div>
            </div>
        </>
    )
}

export default ALLERGY_MANAGMENT