import React from 'react'

import Carousel from 'react-bootstrap/Carousel';

// Import Swiper styles
import 'swiper/css';
import {  Card } from 'react-bootstrap';


const Testmonialss = () => {


  return (
    <>

      <Carousel data-bs-theme="light"  indicators={false} >
        <Carousel.Item>
          <div className='container card-wrapper'>
            <Card className="carded" >
            <Card.Img variant="top" src="avatar.png" style={{backgroundColor:'rgb(17, 112, 150)',borderRadius:'50%',width:'35%',height:'45%',marginLeft:'auto',marginRight:'auto',display:'block',marginTop:'10px'}} />

              <Card.Body>
                <Card.Title className='text-center'><b>Vennala</b></Card.Title>
                <Card.Text className='mt-2'>
                is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. 
                </Card.Text>
                
              </Card.Body>
            </Card>
            <Card className="carded" >
            <Card.Img variant="top" src="avatar.png" style={{backgroundColor:'rgb(17, 112, 150)',borderRadius:'50%',width:'35%',height:'45%',marginLeft:'auto',marginRight:'auto',display:'block',marginTop:'10px'}} />

              <Card.Body>
                <Card.Title className='text-center'><b>Vennala</b></Card.Title>
                <Card.Text className='mt-2'>
                is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. 
                </Card.Text>
                
              </Card.Body>
            </Card>
            <Card className="carded" >
            <Card.Img variant="top" src="avatar.png" style={{backgroundColor:'rgb(17, 112, 150)',borderRadius:'50%',width:'35%',height:'45%',marginLeft:'auto',marginRight:'auto',display:'block',marginTop:'10px'}} />

              <Card.Body>
                <Card.Title className='text-center'><b>Kaveri</b></Card.Title>
                <Card.Text className='mt-2'>
                is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. 
                </Card.Text>
                
              </Card.Body>
            </Card>
          </div>

        </Carousel.Item>
        <Carousel.Item>
        <div className='container card-wrapper'>
            <Card className="carded" >
            <Card.Img variant="top" src="avatar.png" style={{backgroundColor:'rgb(17, 112, 150)',borderRadius:'50%',width:'35%',height:'45%',marginLeft:'auto',marginRight:'auto',display:'block',marginTop:'10px'}} />

              <Card.Body>
                <Card.Title className='text-center'><b>Vennala</b></Card.Title>
                <Card.Text className='mt-2'>
                is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. 
                </Card.Text>
                
              </Card.Body>
            </Card>
            <Card className="carded" >
            <Card.Img variant="top" src="avatar.png" style={{backgroundColor:'rgb(17, 112, 150)',borderRadius:'50%',width:'35%',height:'45%',marginLeft:'auto',marginRight:'auto',display:'block',marginTop:'10px'}} />

              <Card.Body>
                <Card.Title className='text-center'><b>Vennala</b></Card.Title>
                <Card.Text className='mt-2'>
                is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. 
                </Card.Text>
                
              </Card.Body>
            </Card>
            <Card className="carded" >
            <Card.Img variant="top" src="avatar.png" style={{backgroundColor:'rgb(17, 112, 150)',borderRadius:'50%',width:'35%',height:'45%',marginLeft:'auto',marginRight:'auto',display:'block',marginTop:'10px'}} />

              <Card.Body>
                <Card.Title className='text-center'><b>Kaveri</b></Card.Title>
                <Card.Text className='mt-2'>
                is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. 
                </Card.Text>
                
              </Card.Body>
            </Card>
          </div>
        </Carousel.Item>
        <Carousel.Item>
        <div className='container card-wrapper'>
            <Card className="carded" >
            <Card.Img variant="top" src="avatar.png" style={{backgroundColor:'rgb(17, 112, 150)',borderRadius:'50%',width:'35%',height:'45%',marginLeft:'auto',marginRight:'auto',display:'block',marginTop:'10px'}} />

              <Card.Body>
                <Card.Title className='text-center'><b>Vennala</b></Card.Title>
                <Card.Text className='mt-2'>
                is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. 
                </Card.Text>
                
              </Card.Body>
            </Card>
            <Card className="carded" >
            <Card.Img variant="top" src="avatar.png" style={{backgroundColor:'rgb(17, 112, 150)',borderRadius:'50%',width:'35%',height:'45%',marginLeft:'auto',marginRight:'auto',display:'block',marginTop:'10px'}} />

              <Card.Body>
                <Card.Title className='text-center'><b>Vennala</b></Card.Title>
                <Card.Text className='mt-2'>
                is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. 
                </Card.Text>
                
              </Card.Body>
            </Card>
            <Card className="carded" >
            <Card.Img variant="top" src="avatar.png" style={{backgroundColor:'rgb(17, 112, 150)',borderRadius:'50%',width:'35%',height:'45%',marginLeft:'auto',marginRight:'auto',display:'block',marginTop:'10px'}} />

              <Card.Body>
                <Card.Title className='text-center'><b>Kaveri</b></Card.Title>
                <Card.Text className='mt-2'>
                is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. 
                </Card.Text>
                
              </Card.Body>
            </Card>
          </div>
        </Carousel.Item>
      </Carousel>






    </>
  )
}

export default Testmonialss