import React from 'react'

const Acute_Bronchitis = () => {
  return (
    <>
      <div style={{height:'120px'}}></div>
    <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex',backgroundColor:"rgb(17, 112, 150)",}}>
        <div className="col text-center strip1" style={{ color: 'white' }}>
          <div className="row mt-4" >
            <h1 style={{textTransform:'capitalize'}}>Acute Bronchitis</h1>
          </div>
          <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
            <li ><a href="/home">Home</a></li>
           
          </ul>
        </div>
      </div>

      <div className='container'>
        <div className='row'>
        <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
        <div className="row">
          <div className="col-lg-7 mb-2">
            <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "rgb(17, 112, 150)" }}>Acute Bronchitis</span>
            </h1>
            <p>
            Acute bronchitis is a common respiratory condition that affects millions of people each year. While it can cause discomfort and distress, it is usually not serious and often resolves on its own with proper care. This article aims to provide a comprehensive overview of acute bronchitis, including its symptoms, causes, risk factors, and treatment options.
            </p>

            <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "rgb(52, 186, 240)" }}>What is Acute Bronchitis?</span>
            </h4 >
            <p style={{ textAlign: 'justify' }}>
            Acute bronchitis is an inflammation of the bronchial tubes, which are the airways that carry air to and from the lungs. When these airways become infected or irritated, they swell and produce excess mucus, leading to symptoms such as coughing, wheezing, and shortness of breath.
            </p>

          </div>
          <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
            <img className="mt-5"  style={{ height: '300px',borderRadius:'15px' }} id="ServicesImg" src='acute.jpg' alt=''></img>
          </div>
        </div>

        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "rgb(52, 186, 240)" }}>Symptoms of Acute Bronchitis:</span>
            </h4 >
        <ul>
            <li><b>Persistent Cough </b>: One of the hallmark symptoms of acute bronchitis is a persistent cough that may produce clear, yellowish, or greenish mucus.</li>
            <li><b>Chest Discomfort</b>: Individuals with acute bronchitis may experience mild to moderate chest discomfort or a feeling of tightness.</li>
            <li><b>Wheezing</b>: Some people may experience a whistling or high-pitched sound while breathing, known as wheezing.</li>
            <li><b>Shortness of Breath</b> : Breathing difficulties and shortness of breath can occur, especially during physical activity.</li>
            <li><b>Sore Throat</b>: A sore or scratchy throat might be present due to irritation and inflammation of the airways.</li>
            <li><b>Mild Fever</b> : In some cases, a low-grade fever may accompany acute bronchitis.</li>
        </ul>
        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "rgb(52, 186, 240)" }}>Causes of Acute Bronchitis:</span>
        </h4 >
        <p>Acute bronchitis is commonly caused by viral infections, particularly the same viruses responsible for the common cold and influenza (flu). The most common viral agents are rhinoviruses, influenza viruses, and coronaviruses. Less frequently, acute bronchitis can be caused by bacterial infections or irritants, such as tobacco smoke, air pollution, or chemical fumes.</p>
        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "rgb(52, 186, 240)" }}>Risk Factors:</span>
        </h4 >
        <p>Certain factors may increase the risk of developing acute bronchitis:</p>
        <ul>
            <li><b>Weakened Immune System</b> : Individuals with compromised immune systems are more susceptible to viral and bacterial infections that can trigger bronchitis.</li>
            <li><b>Exposure to Irritants</b> : Regular exposure to smoke, dust, fumes, and other air pollutants can irritate the airways and increase the risk of bronchitis.</li>
            <li><b>Age</b>: Children and the elderly are more vulnerable to respiratory infections, including acute bronchitis.</li>
            <li><b>Smoking</b>: Cigarette smoking significantly raises the likelihood of developing acute bronchitis and can worsen the condition.</li>
            <li><b>Pre-existing Respiratory Conditions</b> : Individuals with conditions like asthma or chronic obstructive pulmonary disease (COPD) have a higher risk of developing acute bronchitis.</li>
           
        </ul>

        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "rgb(52, 186, 240)" }}>Prevention:</span>
        </h4 >
        <p>To reduce the risk of acute bronchitis, consider these preventive measures:</p>
        <ul>
            <li><b>Practice Good Hygiene</b> : Wash your hands frequently, especially during the cold and flu seasons, to minimize the spread of viruses.</li>
            <li><b>Avoid Smoking and Secondhand Smoke</b> : If you smoke, seek assistance to quit, and avoid exposure to secondhand smoke.</li>
            <li><b>Stay Updated on Vaccinations</b> : Get vaccinated against the flu and other preventable respiratory infections, as this can reduce the likelihood of developing complications.</li>
            <li><b>Avoiding Irritants</b>: Minimize exposure to air pollutants, dust, and chemical fumes.</li>
           
        </ul>
        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "rgb(52, 186, 240)" }}>Conclusion:</span>
        </h4 >
        <p>Acute bronchitis is a common respiratory condition characterized by inflammation of the bronchial tubes, leading to coughing, wheezing, and shortness of breath. While it can be uncomfortable, most cases can be managed with rest, hydration, and over-the-counter medications. If you suspect you have acute bronchitis or experience severe symptoms, consult a healthcare professional for proper evaluation and treatment. Taking preventive measures can also help reduce the risk of developing this condition and other respiratory infections.</p>

      </div>

        </div>
      </div>
    </>
  )
}

export default Acute_Bronchitis