import React from 'react'

const Role_diet_nutrients_maintaining_healthy_lungs = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(17, 112, 150)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>Role of Diet and Nutrients in Maintaining Healthy Lungs</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/home">Home</a></li>
                    </ul>
                </div>
            </div>
            <div className='container'>
                <div className='row'>
                    <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                        <div className="row">
                            <div className="col-lg-7 mb-2">
                                <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                                    <span style={{ color: "rgb(17, 112, 150)" }}>Role of Diet and Nutrients in Maintaining Healthy Lungs</span>
                                </h1>
                                <p style={{ alignItems: 'justify' }}>
                                Lung health issues like COPD, asthma, pollution-related problems, bronchitis, TB (Tuberculosis), and lung infections are rising. This is attributed mainly to lifestyle changes, smoking, and rising pollution levels (both indoors and outdoors). People who face breathing problems, cough with or without phlegm, chest pain, weight loss, etc, must visit their doctor early to identify and manage the situation properly.
                                </p>
                                <p style={{ alignItems: 'justify' }}>
                                The importance of nutrients and supplements in pulmonary ailments is under-recognised and often poorly understood, even among medical professionals. Our society is unaware of the importance of maintaining good nutrition in lung diseases. We must know that most pulmonary ailments result from an imbalance between oxidants & antioxidants and proinflammatory vs anti-inflammatory molecules. Therefore, it is logical to add substances rich in antioxidants and anti-inflammatory properties to our diet.
                                </p>

                                <p style={{ alignItems: 'justify' }}>
                                </p>
                            </div>
                            <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                                <img className="mt-5" style={{ height: '300px', width: '80%', borderRadius: '15px' }} id="ServicesImg" src='HealthyLungs.jpg' alt=''></img>
                            </div>
                        </div>
                        <p style={{ alignItems: 'justify' }}>
                        Some essential lung nutrients include: Vitamin A, Vitamin C, Vitamin E, Vitamin D, Vitamin B12, omega-3 polyunsaturated fatty acids, zinc, selenium, flavonoids, curcumins, magnesium, folate, and niacin.
                        </p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}>Effective Tips For Maintaining Lung Health Are:</span>
                        </h4 >
                      <ul style={{ alignItems: 'justify' }}>
                        <li>1.	Avoid and quit smoking</li>
                        <li>2.	Avoid chewing tobacco</li>
                        <li>3.	Avoid alcohol</li>
                        <li>4.	Drink plenty of fluids, ideally up to 3L/day (unless you are on restricted fluids by a doctor's advice)</li>
                        <li>5.	Walk daily and maintain weight</li>
                        <li>6.	Consume antioxidant-rich foods: apples, pears, berries (raspberry, blueberry, and strawberries), beetroot juice, green leafy vegetables like spinach, cauliflower, kiwi, lemons, oranges, papaya, almonds, peanuts, fish, other seafood, poultry, brown rice, lentils, cereals, beans, peas, red bell pepper, and chilli pepper.</li>
                        <li>7.	Consume anti-inflammatory foods: tomatoes, olive oil, green leafy vegetables, almonds, walnuts, fish, berries, cherries, oranges, apricots, grapes, melons, pears, dates, pineapples, and turmeric.</li>
                        <li>8.	Avoid white bread, margarine, soda, red meat, french fries, pastries, sweets, butter, excessive cheese, and fried foods.</li>
                        <li>9.	Diet should include mushrooms, carrots, radish, onions, garlic, eggplants, cabbage, cauliflower, turnips, okra (lady finger/bhindi), pumpkin, quinoa, oats, barley, and wheat bread.  </li>
                        <li>10.	Green tea has both antioxidant and anti-inflammatory properties.</li>
                        <li>11.	Drinking a moderate amount of coffee, 1-2 cups per day, is healthy.</li>
                        <li>12.	Low carbohydrate, high protein, antioxidant, and anti-inflammatory diets are advisable. Powder formulations are also available for patients. </li>
                        <li>13.	Do simple deep breathing exercises and diaphragmatic breathing.</li>
                        <li>14.	Get your flu and pneumococcal vaccinations as per your doctor's advice.</li>
                        <li>15.	Visit your doctor regularly if you have lung problems and follow their advice.</li>
                        <li>16.	Do not stop any medicines without prior consultation with doctors.</li>
                        <li>17.	Avoid travelling to polluted areas.</li>
                        <li>18.	Use N-95 masks when outdoors in polluted areas.</li>
                        <li>19.	Wash hands regularly or use hand sanitisers when outdoors.</li>
                        <li>20.	Limit indoor pollution.</li>
                      </ul>
                     
                    </div>
                </div>
            </div>
        </>
    )
}

export default Role_diet_nutrients_maintaining_healthy_lungs