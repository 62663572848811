import React from 'react'

const Know_About_Asthma = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(17, 112, 150)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>Know About Asthma</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/home">Home</a></li>
                    </ul>
                </div>
            </div>
            <div className='container'>
                <div className='row'>
                    <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                        <div className="row">
                            <div className="col-lg-7 mb-2">
                                <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                                    <span style={{ color: "rgb(17, 112, 150)" }}>Know About Asthma</span>
                                </h1>
                                <p style={{ alignItems: 'justify' }}>
                                    Asthma is a chronic lung disease which is characterized by breathing difficulties and can often restrict daily activities for individuals affected by the condition. It involves the narrowing and inflammation of the airways, accompanied by excessive mucus production, leading to coughing, wheezing, and shortness of breath.
                                </p>
                                <p style={{ alignItems: 'justify' }}>
                                    While some individuals may experience only mild symptoms, asthma can occasionally be challenging and cause stress. However, it doesn't have to be a debilitating condition. Asthma can significantly impact daily life and even pose life-threatening risks during asthma attacks.
                                </p>

                                <p style={{ alignItems: 'justify' }}>
                                    Childhood-onset asthma persists into adulthood; it is a lifelong condition that can be managed but not completely cured. Symptoms may vary, with children experiencing intermittent symptoms and adults experiencing more persistent ones.
                                </p>
                            </div>
                            <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                                <img className="mt-5" style={{ height: '300px', width: '80%', borderRadius: '15px' }} id="ServicesImg" src='AsthmaBlog.png' alt=''></img>
                            </div>
                        </div>
                        <p style={{ alignItems: 'justify' }}>
                            Although asthma currently has no cure, its symptoms can be effectively controlled with medication. It is crucial to work closely with healthcare providers to determine the most suitable treatment plan, which may require adjustments over time. Patients should be prepared to provide their medical history, including details of respiratory symptoms, exacerbations, allergies, and other relevant health conditions, as well as their current medications. Collaborating with your doctor and actively engaging in assessing treatment options increases the likelihood of obtaining maximum benefits. Treatment duration depends on the severity of the problem, the patient's age, and other associated health issues.
                        </p>
                        <p style={{ alignItems: 'justify' }}>
                            Inhalation therapy plays a vital role in both the acute and long-term management of asthma. However, there are prevalent misconceptions and incorrect practices regarding the use of inhalers in some regions, such as India. These misconceptions hinder proper treatment-seeking behavior and adherence to prescribed medications. Addressing these myths is essential to improve disease management and reduce morbidity and mortality rates. Patients should also be aware of personalized emergency instructions, have an Asthma Action Plan, and monitor the frequency of their rescue medication use.
                        </p>
                        <p style={{ alignItems: 'justify' }}>
                            Triggers for asthma attacks include dust, molds, pets, and stress. Respiratory infections, particularly coughs and colds, can have a debilitating effect on individuals with asthma. Annual flu shots are recommended for asthma patients to minimize the risk of serious complications. Viral infections can act as triggers for asthma attacks.
                        </p>
                        <p style={{ alignItems: 'justify' }}>
                            In conclusion, living with asthma requires effectively managing various factors that can worsen or trigger an attack. It is important for individuals to accept their condition and not be afraid of it. Opting for the best-in-class inhalation therapy and making informed choices about inhaler usage are crucial steps in managing asthma effectively."
                        </p>
                     
                    </div>
                </div>
            </div>
        </>
    )
}

export default Know_About_Asthma