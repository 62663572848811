import React from 'react'

const Critical_Care = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(17, 112, 150)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>Critical Care</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/home">Home</a></li>

                    </ul>
                </div>
            </div>

            <div className='container'>
                <div className='row'>
                    <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                        <div className="row">
                            <div className="col-lg-7 mb-2">
                                <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                                    <span style={{ color: "rgb(17, 112, 150)" }}>Critical Care</span>
                                </h1>
                                <p>
                                Advanced Respiratory critical care in Guntur are the units of Pulmonology and Respiratory Rehabilitation departments. These units are equipped with centrally controlled systems for oxygen delivery, air-suction, and air-conditioning in a spacious environment. Patients in such units need continuous monitoring and care as their disease condition has worsened or advanced to a critical point. Patients are unable to breathe or whose heart/Lung transplant has occurred. Such as in diseases like:
                                </p>
                                <p>Obstructive Lung Diseases (COPD)</p>
                                <p>Interstitial Lung Diseases (ILD)</p>
                                <p>Pulmonary vascular diseases</p>
                                <p>These services provide care for patients suffering from acute respiratory failure (ARF) or by an acute exacerbation of chronic respiratory failure (CRF).</p>
                              

                            </div>
                            <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                                <img className="mt-5" style={{ width: '100%', height: '300px', borderRadius: '15px' }} id="ServicesImg" src='WhatsApp Image 2023-08-05 at 11.54.37 AM.jpeg' alt=''></img>
                            </div>
                        </div>
                        <p>The main aim at respiratory critical care hospitals in Guntur is to provide clinical control, Ventilators, ECMO (extracorporeal membrane oxygenation), and air transfer for ECMO.</p>






                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}>Advanced Respiratory Critical Care Services Including ECMO & Air Transfer For ECMO Services</span>
                        </h4 >
                        <p>ECMO is a similar machine to a heart-lung bypass used in open-heart surgery. This machine helps to remove blood from the heart and lungs out of the body to provide rest to the organs. They are of two types VV ECMO and VA ECMO.</p>
                        <p>Providing and removing support of ECMO requires surgery but generally, it is done in the patient's room itself.</p>
                        <p>When a patient is on ECMO treatment in Guntur then they are also on a ventilator, which helps to transfer air. It is an advanced type of mechanical life system where blood is removed from the body, oxygenates, removes carbon dioxide from the blood, and then returns blood into the body.</p>
                        <p>The main benefit is to provide life support to the patient and increases the chances of survival.</p>
                        <p>Get lung critical care treatment at interventional pulmonology hospitals in Guntur We are providing our best Advanced respiratory critical care services for people who are living in and around Guntur</p>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Critical_Care