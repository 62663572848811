import React from 'react'

const Infection_diseases = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(17, 112, 150)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>MANAGEMENT OF INFECTIONS LIKE TUBERCULOSIS, PNEUMONIA, COVID 19 & SEPSIS</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/home">Home</a></li>
                    </ul>
                </div>
            </div>
            <div className='container'>
                <div className='row'>
                    <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                        <div className="row">
                            <div className="col-lg-7 mb-2">
                                <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                                    <span style={{ color: "rgb(17, 112, 150)" }}>MANAGEMENT OF INFECTIONS LIKE TUBERCULOSIS, PNEUMONIA, COVID 19 & SEPSIS</span>
                                </h1>
                                <p style={{ alignItems: 'justify' }}>
                                Tuberculosis (TB) is a possibly serious illness that influences the lungs. The microbes that cause tuberculosis are spread from one individual to another through tiny droplets delivered into the air by means of coughs and sneezes.    
                                </p>
                                <p style={{ alignItems: 'justify' }}>
                                Most patients having active tuberculosis ought to be dealt with at first with isoniazid, rifampin, pyrazinamide, and ethambutol for about two months, trailed by 18 weeks of treatment with isoniazid and rifampin if necessary. Rehash cultures ought to be performed by pulmonology doctors in Guntur after the underlying eight-week treatment.     
                                </p>

                            </div>
                            <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                                <img className="mt-5" style={{ height: '300px', width: '80%', borderRadius: '15px' }} id="ServicesImg" src='WhatsApp Image 2023-08-05 at 11.54.37 AM (3).jpeg' alt=''></img>
                            </div>
                        </div>
                        <p style={{ alignItems: 'justify' }}><b>
                        Make an appointment with pneumonia specialist for sepsis treatment, lung infection treatment, tuberculosis treatment in Guntur.
                        </b></p>
                        <p style={{ alignItems: 'justify' }}>
                        Pneumonia
                        Pneumonia is defined as respiratory infection with focal abnormalities on chest x-ray (CXR).
                        </p>
                        <p style={{ alignItems: 'justify' }}>Oxygen as appropriate to accomplish target oxygen saturations:</p>
                        <p style={{ alignItems: 'justify' }}>94-98% for most patients</p>
                        <p style={{ alignItems: 'justify' }}>88-92% for those in danger of hypercapnic respiratory failure</p>
                        <p style={{ alignItems: 'justify' }}>Management Of Infections Like Tuberculosis, Pneumonia, COVID 19 and Sepsis</p>
                        <p style={{ alignItems: 'justify' }}>Antibiotics</p>
                        <p style={{ alignItems: 'justify' }}>Studies show that increase in mortality rates of young patients when antibiotic treatment is delayed. Initiate antibiotics immediately.</p>
                        <p style={{ alignItems: 'justify' }}>Ponder to change to appropriate antibiotic if exact organism recognized.</p>
                        <p style={{ alignItems: 'justify' }}>Consider step down while improving</p>
                        <p style={{ alignItems: 'justify' }}>IV fluids if appropriate (too much fluid loss, fever, acute kidney injury)</p>
                        <p style={{ alignItems: 'justify' }}>Analgesia for pleuritic pain</p>
                        <p style={{ alignItems: 'justify' }}>Physiotherapy if persistent sputum or mucus plugging.</p>
                        <p style={{ alignItems: 'justify' }}>If clinical concerns, move the patient to high dependency unit</p>
                        <p style={{ alignItems: 'justify' }}>DVT prophylaxis</p>
                        <p style={{ alignItems: 'justify' }}>Repeat the CRP and CXR, consider early respiratory referral if not improved in 3 days</p>
                        <p style={{ alignItems: 'justify' }}><b>Covid-19 management</b></p>
                        <p style={{ alignItems: 'justify' }}>Isolate yourself in a well-ventilated room. Utilize a triple layer clinical mask, dispose of masks following 8 hours of utilization or prior in the event that they become wet or noticeably dirty. In case of a caregiver going into the room, both guardian and patient might consider utilizing N-95 mask. Mask ought to be disposed only after sanitizing it with 1% Sodium Hypochlorite. Take rest and drink lots of liquids to keep up with sufficient hydration. Follow respiratory etiquettes consistently. Regular hand washing with cleanser and water for no less than 40 seconds or clean with alcohol-based sanitizer. Try not to impart individual things to others in the family. Make sure to clean up the surfaces in the room that are contacted frequently (tabletops, door knobs, handles, and so on) with 1% hypochlorite solution. Monitor temperature day by day. Monitor oxygen saturation with a pulse oximeter every day. Interface with the treating lung specialist in Guntur quickly if any symptoms worsen.</p>
                        <p style={{ alignItems: 'justify' }}>Treatment for patients with mild/asymptomatic disease in home isolation</p>
                        <p style={{ alignItems: 'justify' }}>Patients should be in contact with a treating lung cancer doctor in Guntur and instantly report in the event of any deteriorating.</p>
                        <p style={{ alignItems: 'justify' }}>Proceed with the meds for other co-morbid sickness in the wake of counselling the treating doctor.</p>
                        <p style={{ alignItems: 'justify' }}>Patients to follow indicative management for fever, running nose and cough, as justified.</p>
                        <p style={{ alignItems: 'justify' }}>Patients might perform warm water swishes or take steam inward breath two times every day.</p>
                        <p style={{ alignItems: 'justify' }}>When to look for sure fire clinical consideration:</p>
                        <p style={{ alignItems: 'justify' }}>Trouble in breathing</p>
                        <p style={{ alignItems: 'justify' }}>Plunge in oxygen saturation (SpO2 less then '94%' on room air)</p>
                        <p style={{ alignItems: 'justify' }}>Steady torment/pressure in the chest</p>
                        <p style={{ alignItems: 'justify' }}>Mental disarray or inability to stimulate</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Infection_diseases