import React from 'react'

const Asthma_in_adults_and_children = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(17, 112, 150)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>Asthma- Causes, Symptoms, and Treatment in Adults and Children</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/home">Home</a></li>
                    </ul>
                </div>
            </div>
            <div className='container'>
                <div className='row'>
                    <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                        <div className="row">
                            <div className="col-lg-7 mb-2">
                                <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                                    <span style={{ color: "rgb(17, 112, 150)" }}>Asthma-Causes, Symptoms, and Treatment in Adults and Children</span>
                                </h1>
                                <p style={{ alignItems: 'justify' }}>
                                    Asthma is a chronic respiratory condition that affects millions of people worldwide, both adults and children. Understanding the causes, recognizing the symptoms, and knowing the appropriate treatments are crucial for managing this condition effectively. In this blog post, we'll explore asthma in detail, covering its causes, symptoms, and treatment options for both adults and children.
                                </p>

                                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                                    <span style={{ color: "rgb(52, 186, 240)" }}>Causes of Asthma:</span>
                                </h4 >
                                <ul>
                                    <li><b>Genetic Predisposition: </b>Asthma tends to run in families. If one or both parents have asthma, a child is more likely to develop it.</li>


                                </ul>
                            </div>
                            <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                                <img className="mt-5" style={{ height: '300px', width: '80%', borderRadius: '15px' }} id="ServicesImg" src='AsthmaCauses.jpg' alt=''></img>
                            </div>
                        </div>
                        <ul>
                            <li><b>Environmental Factors: </b>Exposure to allergens or irritants in the environment can trigger asthma. Common triggers include pollen, dust mites, mold, pet dander, smoke, and air pollution.</li>

                            <li><b>Respiratory Infections: </b>Severe respiratory infections, especially in early childhood, can increase the risk of developing asthma.</li>
                            <li><b>Occupational Exposure: </b>Certain work environments with high exposure to irritants or chemicals can lead to the development of asthma in adults.</li>
                            <li><b>Allergies: </b>Allergic reactions to substances like pollen, mold spores, or animal dander can lead to asthma symptoms.</li>

                        </ul>

                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}>Symptoms of Asthma:</span>
                        </h4 >
                        <ul>
                            <li><b>Shortness of Breath: </b>Individuals with asthma often experience difficulty breathing, especially during physical activity or at night.</li>
                            <li><b>Wheezing: </b>Wheezing is a high-pitched whistling sound that occurs during breathing, particularly when exhaling.</li>
                            <li><b>Coughing: </b>Persistent coughing, especially at night or early in the morning, can be a symptom of asthma.</li>
                            <li><b>Chest Tightness: </b>People with asthma may feel a sensation of tightness or pressure in their chest.</li>
                            <li><b>Increased Mucus Production: </b>Asthma can lead to an overproduction of mucus in the airways, causing further difficulty in breathing.</li>

                        </ul>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}>Asthma in Children:</span>
                        </h4 >
                        <p style={{ alignItems: 'justify' }}>
                            Children may exhibit slightly different symptoms compared to adults. They might:
                        </p>
                        <ul>
                            <li><b>Have Recurrent Coughs: </b>Chronic coughing, especially at night or early in the morning, is a common symptom.</li>
                            <li><b>Show Reduced Activity Levels: </b>Children with asthma may avoid physical activities to cope with their breathing difficulties.</li>
                            <li><b>Experience Trouble Sleeping: </b>Nighttime symptoms can disrupt sleep patterns.</li>
                            <li><b>Show Changes in Facial Expressions: </b>Younger children may display anxiety or discomfort through their facial expressions.</li>
                            
                        </ul>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}>Treatment Options:</span>
                        </h4 >
                        <ul>
                            <li><b>Medications:</b>
                                <ul>
                                    <li><b>Quick-Relief Inhalers: </b>These provide immediate relief during asthma attacks.</li>
                                    <li><b>Long-Term Control Medications: </b>Taken daily to manage symptoms and prevent attacks.</li>
                                </ul>
                            </li>
                            <li><b>Allergen Avoidance: </b>Identifying and minimizing exposure to triggers like dust mites, pollen, and pet dander can significantly reduce asthma symptoms.</li>
                            <li><b>Lifestyle Modifications: </b>This includes regular exercise, maintaining a healthy diet, and avoiding tobacco smoke.</li>
                            <li><b>Immunizations: </b>Keeping up with vaccinations, especially for respiratory infections like the flu, can help prevent asthma exacerbations.</li>
                            <li><b>Regular Check-Ups: </b>Monitoring asthma with a healthcare provider ensures proper management and adjustment of treatment plans as needed.</li>
                        </ul>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}></span>
                        </h4 >
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}></span>
                        </h4 >
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}></span>
                        </h4 >
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}></span>
                        </h4 >
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}></span>
                        </h4 >
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}></span>
                        </h4 >

                        <p style={{ alignItems: 'justify' }}>
                        </p>
                        <p style={{ alignItems: 'justify' }}>
                        </p>
                        <p style={{ alignItems: 'justify' }}>
                        </p>
                        <p style={{ alignItems: 'justify' }}>
                        </p>
                        <p style={{ alignItems: 'justify' }}>
                        </p>
                        <p style={{ alignItems: 'justify' }}>
                        </p>
                        <p style={{ alignItems: 'justify' }}>
                        </p>
                        <p style={{ alignItems: 'justify' }}>
                        </p>
                        <p style={{ alignItems: 'justify' }}>
                        </p>
                        <p style={{ alignItems: 'justify' }}>
                        </p>
                        <p style={{ alignItems: 'justify' }}>
                        </p>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Asthma_in_adults_and_children