import React from 'react'

const Quit_Smoking_Today_Before_Its_Too_Late = () => {
  return (
    <>
      <div style={{ height: '120px' }}></div>
      <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(17, 112, 150)", }}>
        <div className="col text-center strip1" style={{ color: 'white' }}>
          <div className="row mt-4" >
            <h1 style={{ textTransform: 'uppercase' }}>Quit Smoking Today Before It’s Too Late</h1>
          </div>
          <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
            <li ><a href="/home">Home</a></li>
          </ul>
        </div>
      </div>
      <div className='container'>
        <div className='row'>
          <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
            <div className="row">
              <div className="col-lg-7 mb-2">
                <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                  <span style={{ color: "rgb(17, 112, 150)" }}>Quit Smoking Today Before It’s Too Late</span>
                </h1>
                <p style={{ alignItems: 'justify' }}>
                  Tobacco kills almost half of its users. Studies show tobacco kills more than 8 million people each year, out of which around 7 million of those deaths are a result of direct tobacco use, while the other 1.2 million deaths are a result of non-smokers being exposed to second-hand smoke. Almost 80% of the world's tobacco users reside in low and middle-income countries.                            </p>
                <p style={{ alignItems: 'justify' }}>
                  In 2020, 22.3% of the global population used tobacco, of which 36.7% were men and 7.8% of them were women. Tobacco smoke is enormously harmful to health. Smoking harms almost every organ of the body. Smoking affects the lungs, heart, GI tract, central nervous system, kidneys and reproductive system. Smoking is the primary cause of Emphysema, Lung cancer, Chronic Bronchitis and COPD.
                </p>


              </div>
              <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                <img className="mt-5" style={{ height: '300px', width: '80%', borderRadius: '15px' }} id="ServicesImg" src='SmokingBlog-transformed.png' alt=''></img>
              </div>
            </div>
            <p style={{ alignItems: 'justify' }}>
              Smoking makes the body more susceptible and defenceless against infectious respiratory diseases such as Pneumonia, Aspergillosis, Tuberculosis, etc. Smoking also increases the risk of cataracts and poor eyesight. Smoking can lead to heart attack and stroke. Smoking is also a risk factor for erectile dysfunction and infertility. Passive smoking is equally dangerous and must be strongly discouraged.
            </p>
            <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "rgb(52, 186, 240)" }}>Hazards of smoking</span>
            </h4 >
            <p style={{ alignItems: 'justify' }}>
            Tobacco smoke contains more than 7000 chemicals, out of which about 70 are known carcinogens. Tobacco smoke is harmful to your body. Smoking harms every organ of the body.
                        </p>
            <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}>What is withdrawal?</span>
                        </h4 >
            <p style={{ alignItems: 'justify' }}>
            A lot of smokers say they want to quit, but actually, less than 10% of smokers succeed in quitting. According to the CDC (Center for Disease Control and Prevention), the main cause of not being able to quit is nicotine. Withdrawal symptoms can start a few hours after quitting and may last a few weeks.
                        </p>
                        <p>People who try to quit may feel anxious, irritated, and depressed and may develop insomnia, mood changes, hunger and headaches due to withdrawal symptoms. However, with tips from physicians and strong determination, one can overcome withdrawal symptoms.</p>
            <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}>How to quit Smoking? Finally!</span>
                        </h4 >
            <p style={{ alignItems: 'justify' }}>
            Recent studies have shown that if we have strong determination, we can quit smoking easily. Tobacco dependence is a chronic disease that requires repeated intervention and multiple attempts to quit. Effective treatments exist that can significantly increase rates of long-term abstinence.
                  </p>
          
            <p style={{ alignItems: 'justify' }}>
            We must explain to the patients that, do they wish to
             </p>
           <ul>
            <li>Be around for their loved ones?</li>
            <li>Have better health?</li>
            <li>Set a healthy example for their children?</li>
            <li>Protect their family from passive smoking?</li>
           </ul>

          </div>
        </div>
      </div>
    </>
  )
}

export default Quit_Smoking_Today_Before_Its_Too_Late