import React from 'react'

const Pulmonary_Emphysema = () => {
  return (
    <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(17, 112, 150)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>Pulmonary Emphysema</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/home">Home</a></li>

                    </ul>
                </div>
            </div>
            <div className='container'>
                <div className='row'>
                    <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                        <div className="row">
                            <div className="col-lg-7 mb-2">
                                <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                                    <span style={{ color: "rgb(17, 112, 150)" }}>Pulmonary Emphysema</span>
                                </h1>
                                <p style={{ textAlign: 'justify' }}>
                                Pulmonary emphysema is a chronic and progressive lung condition that falls under the broader term Chronic Obstructive Pulmonary Disease (COPD). It primarily affects the alveoli, tiny air sacs in the lungs responsible for gas exchange, causing them to lose their elasticity and impairing breathing. Understanding pulmonary emphysema, its causes, symptoms, and management is vital for those affected and their caregivers.</p>
                                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                                    <span style={{ color: "rgb(52, 186, 240)" }}>Causes and Risk Factors:</span>
                                </h4 >
                                <p style={{ textAlign: 'justify' }}>
                                Pulmonary emphysema is often caused by long-term exposure to harmful airborne irritants, most notably cigarette smoke. Other risk factors include exposure to air pollution, occupational dust, and genetic predisposition. Smoking cessation is the most crucial step in preventing the progression of emphysema.</p>
                            </div>
                            <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                                <img className="mt-5" style={{ width:'80%',height: '300px', borderRadius: '15px' }} id="ServicesImg" src='PULMONARY_EMPHYSEMA.jpg' alt=''></img>
                            </div>
                        </div>

                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}>Understanding the Pathophysiology:</span>
                        </h4 >
                        <p>n pulmonary emphysema, the air sacs lose their elasticity and become damaged over time. This results in the collapse of some alveoli and the formation of larger, less efficient air spaces. As a consequence, the lungs' ability to exchange oxygen and carbon dioxide is impaired, leading to breathlessness and other respiratory symptoms.</p>


                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}>Pulmonary Emphysema Symptoms:</span>
                        </h4 >
                        <p>Typical symptoms include shortness of breath, especially during physical activities, chronic cough, wheezing, chest tightness, and fatigue. As the condition progresses, everyday activities like climbing stairs or carrying items may become increasingly difficult.</p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}>Diagnosis and Staging:</span>
                        </h4 >
                        <p>Diagnosing pulmonary emphysema involves medical history, physical examination, and lung function tests such as spirometry. Chest X-rays and CT scans help visualize the lungs and confirm the diagnosis. The Global Initiative for Chronic Obstructive Lung Disease (GOLD) staging system classifies the severity of emphysema based on lung function test results.</p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}> Managing Pulmonary Emphysema:</span>
                        </h4 >
                        <p>While there is no cure for pulmonary emphysema, treatments focus on managing symptoms and improving quality of life. Smoking cessation is the most effective measure to slow disease progression. Medications, pulmonary rehabilitation, and oxygen therapy may be prescribed to alleviate symptoms and enhance lung function. In severe cases, surgical options such as lung volume reduction surgery or lung transplantation might be considered.</p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}>Conclusion</span>
                        </h4 >
                        <p>Pulmonary emphysema is a chronic lung condition that affects millions of people globally, especially those with a history of smoking or exposure to harmful environmental factors. Recognizing the symptoms and seeking early medical intervention is crucial for effectively managing the disease and improving the patient's quality of life. Smoking cessation and adopting a healthy lifestyle are essential steps in slowing the progression of pulmonary emphysema and reducing its impact on respiratory health. Seeking medical advice, following prescribed treatments, and staying informed about the condition can significantly help individuals affected by pulmonary emphysema lead fulfilling lives.</p>

                    </div>

                </div>
            </div>
        </>
  )
}

export default Pulmonary_Emphysema