import React from 'react'

const Swasa_Clinic = () => {
  return (
    <>
    <div style={{ height: '120px' }}></div>
    <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(17, 112, 150)", }}>
        <div className="col text-center strip1" style={{ color: 'white' }}>
            <div className="row mt-4" >
                <h1 style={{ textTransform: 'uppercase' }}>Swasa Clinic</h1>
            </div>
            <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                <li ><a href="/home">Home</a></li>
            </ul>
        </div>
    </div>

    <div className='container'>
        <div className='row'>
            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(17, 112, 150)" }}>Swasa Clinic</span>
                        </h1>
                        <p>
                        Welcome to Swasa Clinic, a pioneering establishment founded by Dr. Bharani within the esteemed Shri Hospital. Our mission is to provide comprehensive respiratory care that encompasses diagnosis, treatment, and education, all aimed at fostering a life of unhindered breathing and holistic well-being.    
                        </p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}>About Swasa Clinic:</span>
                        </h4 >
                        <p style={{ textAlign: 'justify' }}>
                        Swasa, meaning "breath" in Sanskrit, is a sanctuary of healing curated by Dr. Bharani, a distinguished name in the realm of respiratory medicine. Dr. Bharani's unwavering commitment to respiratory health has culminated in the creation of Swasa Clinic, a center dedicated to serving individuals with respiratory conditions and facilitating their journey to wellness.  
                        </p>
                    </div>
                    <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                        <img className="mt-5" style={{ width: '80%', height: '300px', borderRadius: '15px' }} id="ServicesImg" src='swasa.png' alt=''></img>
                    </div>
                </div>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(52, 186, 240)" }}>Our Vision:</span>
                </h4 >
                <p>
                Swasa Clinic envisions a world where every individual can revel in the simple act of breathing, unburdened by respiratory concerns. Our vision is to become a haven of healing where cutting-edge medical expertise, compassion, and patient empowerment converge.
                </p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(52, 186, 240)" }}>Key Attributes:</span>
                </h4>
                <p><b>Expert Respiratory Care:</b> Swasa Clinic is synonymous with excellence in respiratory care. Our seasoned medical team, led by Dr. Bharani, employs their deep expertise to provide accurate diagnoses and customized treatment plans.</p>
                <p><b>Holistic Approach:</b> Beyond medical interventions, Swasa Clinic recognizes the interconnectedness of physical, emotional, and environmental factors. Our holistic approach addresses all facets of well-being.</p>
                <p><b>State-of-the-Art Facilities:</b> Equipped with advanced diagnostic tools and treatment modalities, Swasa Clinic ensures that patients receive the most up-to-date care for respiratory ailments.</p>
                <p><b>Patient-Centric Philosophy:</b> We prioritize patient involvement and education, ensuring that individuals are informed partners in their healthcare journey.</p>
                <p><b>Community of Care:</b> Swasa Clinic fosters a supportive community, connecting patients and their families. We believe in sharing experiences, knowledge, and encouragement.</p>
               
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(52, 186, 240)" }}>Services Offered:</span>
                </h4 >
                <p><b>Comprehensive Diagnostics:</b> Cutting-edge diagnostic tools help us pinpoint the root causes of respiratory issues, enabling accurate treatment strategies.</p>
                <p><b>Tailored Treatment Plans:</b> Each patient is unique, and so are their treatment needs. Swasa Clinic designs individualized treatment plans that consider medical history, lifestyle, and aspirations.</p>
                <p><b>Lifestyle Counselling:</b> We empower patients to make positive lifestyle changes that contribute to improved respiratory health and overall well-being.</p>
                <p><b>Pulmonary Rehabilitation:</b> Swasa Clinic offers specialized rehabilitation programs that enhance lung function and promote physical fitness.</p>
                <p><b>Education and Support:</b> Knowledge is the foundation of effective self-care. Swasa Clinic conducts educational sessions to inform patients about their conditions and teach them how to manage symptoms.</p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(52, 186, 240)" }}>Why Choose Swasa Clinic:</span>
                </h4 >
                
                <p><b>Renowned Leadership:</b> Dr. Bharani's legacy in respiratory medicine ensures that patients receive care of the highest caliber.</p>
                <p><b>Customized Care:</b> Swasa Clinic understands that every patient's journey is distinct. Our tailor-made treatment plans reflect this understanding.</p>
                <p><b>Compassionate Atmosphere:</b> We believe in treating patients with the empathy and respect they deserve, fostering a comforting environment.</p>
                <p><b>Innovative Solutions:</b> Swasa Clinic stays at the forefront of medical advancements to provide patients with the latest and most effective treatment options.</p>
                <p><b>Empowering Lives:</b> Through meticulous care and dedicated support, Swasa Clinic empowers individuals to regain control over their respiratory health.</p>
                <p><b></b></p>
                <p><b></b></p>
               
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(52, 186, 240)" }}>Empowering Lives:</span>
                </h4 >
                <p>Embark on a path of renewed breathing and well-being with Swasa Clinic. Connect with us today to explore our services, schedule a consultation, and experience the transformation that comes with optimal respiratory health.</p>
            </div>
        </div>
    </div>
</>
  )
}

export default Swasa_Clinic