import React from 'react'

const Chronic_obstructive_pulmonary_disease = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(17, 112, 150)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>COPD (Chronic Obstructive Pulmonary Disease)</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/home">Home</a></li>
                    </ul>
                </div>
            </div>


            <div className='container'>
                <div className='row'>
                    <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                        <div className="row">
                            <div className="col-lg-7 mb-2">
                                <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                                    <span style={{ color: "rgb(17, 112, 150)" }}>COPD (Chronic Obstructive Pulmonary Disease)</span>
                                </h1>
                                <p style={{ alignItems: 'justify' }}>
                                    COPD is the fourth leading cause of death globally and the numbers are only rising. COPD is a common preventable and treatable disorder of the airways and air sacs of our lungs which occurs mostly in middle-aged people due to significant exposure to noxious stimuli in the form of particles or gases. The most common noxious stimulus is tobacco smoke which may come from smoking cigarettes, bidis, hookas, or sheeshas or exposure to biomass fuel. The other stimuli may be smoke from the combustion of coal, fossil or any other fuel, air pollution – indoor and outdoor and occupational exposure to fumes, gases, vapours, and other chemicals. All these agents trigger an inflammatory cascade that damages the airways and lungs, resulting in permanent, irreversible damage over a period of time.
                                </p>


                            </div>
                            <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                                <img className="mt-2" style={{ height: '300px', width: '80%', borderRadius: '15px' }} id="ServicesImg" src='COPD.jpg' alt=''></img>
                            </div>
                        </div>

                        <p style={{ alignItems: 'justify' }}>
                            The classical symptoms of chronic obstructive pulmonary disease are a triad of cough, mucous production, and shortness of breath, and the diagnosis is confirmed with a simple test, called spirometry. As the disease progresses, patients start developing loss of weight, appetite, and fatigue. Such patients usually experience worsening of their symptoms during winter months and may require hospitalization depending upon the severity of symptoms. Eventually, some of them may progress to develop chronic respiratory failure and require oxygen and non-invasive ventilatory (NIV) support on a domiciliary basis.
                        </p>
                        <p style={{ alignItems: 'justify' }}>
                            The mainstay of treatment is cessation of exposure to smoke…. So the first thing for such patients to do is to Quit Smoking!! Various modalities for helping someone quit smoking are now available. The usage of medication via inhalers has been shown to reduce the severity and frequency of exacerbations and improve the patient’s health status and exercise capacity. Many of these patients have other comorbidities, which must be treated concomitantly. These patients must receive the three important respiratory vaccines, namely COVID-19, Influenza, and Pneumococcal. They should enrol themselves with a pulmonary rehabilitation program and work upon building their lung capacity. In a select group of patients, surgical or bronchoscopy interventions can be helpful.
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Chronic_obstructive_pulmonary_disease