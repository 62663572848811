import React from 'react'

const Best_Asthma_Hospital_InGuntur = () => {
  return (
    <>
      <div style={{height:'120px'}}></div>
    <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex',backgroundColor:"rgb(17, 112, 150)",}}>
        <div className="col text-center strip1" style={{ color: 'white' }}>
          <div className="row mt-4" >
            <h1 style={{textTransform:'uppercase'}}>Best Asthma Hospital In Guntur</h1>
          </div>
          <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
            <li ><a href="/home">Home</a></li>
           
          </ul>
        </div>
      </div>

      <div className='container'>
        <div className='row'>
        <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
        <div className="row">
          <div className="col-lg-7 mb-2">
            <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "rgb(17, 112, 150)" }}>Asthma</span>
            </h1>
            <p>
            Welcome to our comprehensive guide on asthma, a chronic respiratory condition that affects millions of people worldwide. Whether you or a loved one have been diagnosed with asthma or want to learn more about this condition, this article will provide valuable insights into its causes, symptoms, and effective management strategies.
            </p>

            <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "rgb(52, 186, 240)" }}>What is Asthma?</span>
            </h4 >
            <p style={{ textAlign: 'justify' }}>
            Asthma is a chronic respiratory disorder characterized by inflammation and narrowing of the airways. This can lead to recurrent episodes of wheezing, shortness of breath, chest tightness, and coughing. Asthma symptoms can vary from mild to severe, and they may occur in response to specific triggers or without any apparent cause.
            </p>
          
          </div>
          <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
            <img className="mt-5" style={{ width:'80%',height: '300px',borderRadius:'15px' }} id="ServicesImg" src='Asthma.jpg' alt=''></img>
          </div>
        </div>

        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "rgb(52, 186, 240)" }}>Causes and Triggers:</span>
            </h4 >
            <p style={{ textAlign: 'justify' }}>Chronic bronchitis is characterized by symptoms that persist for at least three months in two consecutive years. Common signs and symptoms include:</p>
            <p style={{textAlign:'justify'}}>The exact cause of asthma remains unknown, but it is believed to result from a combination of genetic and environmental factors. Some common triggers that can worsen asthma </p>
            <p style={{textAlign:'justify'}}>symptoms include:</p>
        <ul>
           <li>Allergens (pollen, pet dander, dust mites)</li>
           <li>Respiratory infections (colds, flu)</li>
           <li>Air pollution and smoke</li>
           <li>Exercise</li>
           <li>Weather changes (cold air, humidity)</li>
           <li>Strong emotions or stress</li>
           <li>Certain medications (aspirin, non-steroidal anti-inflammatory drugs)</li>
           <li>Irritants (perfumes, cleaning products)</li>
        </ul>    
        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "rgb(52, 186, 240)" }}>Symptoms:</span>
            </h4 >
            <p>Asthma symptoms can vary between individuals and may range from mild to severe.</p>
            <p>Common signs of asthma include:</p>
            <ul>
                <li>Wheezing (a whistling sound during breathing)</li>
                <li>Shortness of breath or difficulty breathing</li>
                <li>Chest tightness or pain</li>
                <li>Frequent coughing, especially at night or early morning</li>
                <li>Increased mucus production</li>
            </ul>
        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "rgb(52, 186, 240)" }}>Asthma Management:</span>
        </h4 >
        <p>While asthma is a chronic condition, it can be effectively managed with appropriate treatment.</p>
        <p>Here are some essential management strategies:</p>
        <ul>
            <li><b>Medications</b>: Doctors may prescribe bronchodilators to relieve acute symptoms and anti-inflammatory medications (inhaled corticosteroids) to reduce airway inflammation and prevent asthma attacks.</li>
            <li><b>Asthma Action Plan</b> : Develop a written action plan in collaboration with your healthcare provider. This plan outlines how to adjust medications and actions to take during worsening symptoms or emergencies.</li>
            <li><b>Identify Triggers</b> : Learn to recognize and avoid asthma triggers whenever possible. This may involve making lifestyle changes or using air purifiers in your home.</li>
            <li><b>Regular Check-ups</b> : Schedule regular follow-up appointments with your doctor to assess your asthma control and adjust the treatment plan as needed</li>
            <li><b>Educate Yourself</b> : Understand your condition and how to use medications correctly. Educate family members, friends, and coworkers about asthma and what to do in case of an emergency.</li>
            <li><b>Stay Active with Caution</b> : Regular physical activity is beneficial for overall health, but people with asthma should work with their doctors to find suitable exercise routines and take necessary precautions.</li>
           
        </ul>

        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "rgb(52, 186, 240)" }}>Conclusion:</span>
        </h4 >
        <p>Living with asthma doesn't have to be restrictive. By understanding the condition, recognizing triggers, and following an effective management plan, you can lead a fulfilling life while keeping asthma symptoms under control. Remember, it's essential to work closely with your healthcare provider to optimize your asthma management and achieve the best possible outcomes. Always seek professional medical advice for your individual situation.</p>

      </div>

        </div>
      </div>
    </>
  )
}

export default Best_Asthma_Hospital_InGuntur