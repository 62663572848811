import React from 'react'

const Tuberculosis = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(17, 112, 150)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>Tuberculosis(TB)</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/home">Home</a></li>

                    </ul>
                </div>
            </div>
            <div className='container'>
                <div className='row'>
                    <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                        <div className="row">
                            <div className="col-lg-7 mb-2">
                                <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                                    <span style={{ color: "rgb(17, 112, 150)" }}>Tuberculosis(TB)</span>
                                </h1>
                                <p>
                                Tuberculosis (TB) is a highly infectious disease caused by Mycobacterium tuberculosis bacteria. It primarily affects the lungs but can also spread to other organs. Despite significant progress in healthcare, TB remains a pressing global health issue, affecting millions of people worldwide. Understanding the basics of TB, its symptoms, and preventive measures is crucial in curbing its spread and impact.                                </p>

                                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                                    <span style={{ color: "rgb(52, 186, 240)" }}>The TB Basics:</span>
                                </h4 >
                                <p style={{ textAlign: 'justify' }}>
                                TB is transmitted through the air when an infected person coughs or sneezes, making it highly contagious. Once inside the lungs, the bacteria multiply and cause inflammation, leading to symptoms like a persistent cough, chest pain, fever, and weight loss. Individuals with weakened immune systems, such as those with HIV/AIDS or malnutrition, are at higher risk of developing active TB.                                </p>

                            </div>
                            <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                                <img className="mt-5" style={{ width:'80%',height: '300px', borderRadius: '15px' }} id="ServicesImg" src='TUBERCULOSIS(TB).jpg' alt=''></img>
                            </div>
                        </div>

                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}> Recognizing TB Symptoms:</span>
                        </h4 >
                        <p>Pulmonary TB, the most common form, presents with a prolonged cough that may produce blood or sputum, along with other respiratory symptoms. Extrapulmonary TB can manifest in various ways depending on the affected organs, leading to diverse symptoms.</p>
                    

                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}>Diagnosis and Treatment:</span>
                        </h4 >
                        <p>Early diagnosis is essential for successful TB treatment. Tests like Tuberculin skin tests (TST) and interferon-gamma release assays (IGRAs) detect latent TB infection, while chest X-rays and sputum tests diagnose active TB. TB can be cured with a combination of antibiotics taken over several months. Ensuring treatment adherence is vital to prevent drug-resistant TB.</p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}>Prevention and Control:</span>
                        </h4 >
                        <p>Preventing TB involves vaccination, infection control measures, and screening for latent TB in high-risk individuals. The BCG vaccine provides partial protection, particularly in children. Adequate ventilation in healthcare settings helps reduce transmission risks.</p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}>Global Efforts:</span>
                        </h4 >
                        <p>TB is a global health priority, and international organizations, governments, and healthcare agencies are committed to controlling its spread. The WHO's End TB Strategy aims to reduce TB deaths and incidence by integrating patient-centered care and strengthening health systems.</p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}>Conclusion</span>
                        </h4 >
                        <p>Tuberculosis is a preventable and curable disease, but it requires collective efforts to combat effectively. Early detection, timely treatment, and preventive measures are crucial in curbing the spread of TB. By raising awareness, investing in research, and fostering international collaboration, we can work towards a TB-free future and ensure that every individual has access to quality TB care. Together, let us join hands in the fight against TB and make a difference in global health.</p>

                    </div>

                </div>
            </div>
        </>
    )
}

export default Tuberculosis