import React from 'react'

const Chronic_Bronchitis = () => {
  return (
   <>
    <div style={{height:'120px'}}></div>
    <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex',backgroundColor:"rgb(17, 112, 150)",}}>
        <div className="col text-center strip1" style={{ color: 'white' }}>
          <div className="row mt-4" >
            <h1 style={{textTransform:'uppercase'}}>Chronic Bronchitis</h1>
          </div>
          <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
            <li ><a href="/home">Home</a></li>
           
          </ul>
        </div>
      </div>
      <div className='container'>
        <div className='row'>
        <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
        <div className="row">
          <div className="col-lg-7 mb-2">
            <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "rgb(17, 112, 150)" }}>Chronic Bronchitis</span>
            </h1>
            <p>
            Chronic bronchitis is a long-term respiratory condition that falls under the umbrella term Chronic Obstructive Pulmonary Disease (COPD). It is characterized by the inflammation and narrowing of the bronchial tubes, leading to persistent coughing and breathing difficulties. This article aims to provide a comprehensive overview of chronic bronchitis, including its symptoms, causes, risk factors, and management options.
            </p>

            <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "rgb(52, 186, 240)" }}>What is Chronic Bronchitis?</span>
            </h4 >
            <p style={{ textAlign: 'justify' }}>
            Chronic bronchitis is a type of COPD, primarily affecting the airways. Over time, the constant irritation and inflammation of the bronchial tubes lead to increased mucus production and a chronic cough. The excessive mucus and narrowing of the airways make it challenging to breathe, especially during physical activity or exposure to irritants.
            </p>
          
          </div>
          <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
            <img className="mt-5" style={{ width:'80%',height: '300px',borderRadius:'15px' }} id="ServicesImg" src='CHRONIC_BRONCHITIS.jpg' alt=''></img>
          </div>
        </div>

        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "rgb(52, 186, 240)" }}>Symptoms of Chronic Bronchitis:</span>
            </h4 >
            <p style={{ textAlign: 'justify' }}>Chronic bronchitis is characterized by symptoms that persist for at least three months in two consecutive years. Common signs and symptoms include:</p>

        <ul>
        <li><b>Persistent Cough</b>: A chronic, productive cough that lasts for at least three months each year, for two consecutive years.</li>
        <li><b>Excessive Mucus</b> : Increased production of thick, sticky mucus, often accompanied by phlegm.</li>
        <li><b>Shortness of Breath</b>: Breathlessness or difficulty breathing, especially during physical exertion.</li>
        <li><b>Wheezing</b> : A whistling or high-pitched sound while breathing due to narrowed airways.</li>
        <li><b>Chest Discomfort</b>: A feeling of tightness or pressure in the chest.</li>
        <li><b>Frequent Respiratory Infections</b> : Individuals with chronic bronchitis are more susceptible to respiratory infections.</li>
        
        </ul>

        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "rgb(52, 186, 240)" }}>Causes of Chronic Bronchitis:</span>
            </h4 >
        <p>Chronic bronchitis is typically the result of long-term exposure to irritants, mainly cigarette smoke, although other air pollutants and occupational dust and chemicals can also contribute to the condition. The continuous inhalation of irritants leads to chronic inflammation of the bronchial tubes, causing the symptoms associated with chronic bronchitis.</p>
        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "rgb(52, 186, 240)" }}>Risk Factors:</span>
            </h4 >
            <p>Several factors increase the risk of developing chronic bronchitis:</p>
        <ul>
         <li><b>Smoking</b> : Cigarette smoking is the most significant risk factor for chronic bronchitis. People who smoke or are exposed to secondhand smoke have a much higher likelihood of developing the condition.</li>
         <li><b>Occupational Exposure</b> : Individuals working in industries with exposure to dust, fumes, and chemicals are at an increased risk.</li>
         <li><b>Age and Gender</b> : Chronic bronchitis is more common in people over 40, and women may be at a slightly higher risk than men.</li>
         <li><b>Genetics</b> : A family history of COPD or chronic bronchitis may increase the susceptibility to the condition.</li>
         <li><b>Respiratory Infections</b> : Frequent respiratory infections during childhood can also contribute to the development of chronic bronchitis later in life.</li>
        </ul>
        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "rgb(52, 186, 240)" }}>Conclusion:</span>
        </h4 >
        <p>Chronic bronchitis is a chronic respiratory condition characterized by persistent cough, excessive mucus production, and breathing difficulties. Long-term exposure to irritants, especially cigarette smoke, is the primary cause. While there is no cure for chronic bronchitis, lifestyle changes, medications, and pulmonary rehabilitation can effectively manage the condition and improve the quality of life. Early diagnosis and prompt medical attention are crucial in slowing down the progression of the disease and preventing complications. If you suspect you have chronic bronchitis or experience worsening symptoms, consult a healthcare professional for proper evaluation and management.</p>

      </div>

        </div>
      </div>
   </>
  )
}

export default Chronic_Bronchitis