
import './App.css';
import Header from './Header';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Home from './Home';
import 'bootstrap/dist/css/bootstrap.min.css';
import Contact from './Contact';
import ScrollToTop from "react-scroll-to-top";
import Footer from './Footer';
import Acute_Bronchitis from './Acute_Bronchitis';
import Chronic_Bronchitis from './Chronic_Bronchitis';
import Best_Asthma_Hospital_InGuntur from './Best_Asthma_Hospital_InGuntur';
import Chronic_Obstructive_Pulmonary_Diseases from './Chronic_Obstructive_Pulmonary_Diseases';
import Pneumonia from './Pneumonia';
import Tuberculosis from './Tuberculosis';
import Pulmonary_Emphysema from './Pulmonary_Emphysema';
import Dr_Bharani_Rachagulla from './Dr.Bharani_Rachagulla';
import Why_ChooseUs from './Why_ChooseUs';
import Interventional_Pulmonology from './Interventional_Pulmonology';
import Infection_diseases from './Infection_diseases';
import ILD from './ILD';
import ALLERGY_MANAGMENT from './ALLERGY_MANAGMENT';
import Critical_Care from './Critical_Care';
import Swasa_Clinic from './Swasa_Clinic';
import Targeted_Asthma_Therapy from './Targeted_Asthma_Therapy';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Blogs from './Blogs';
import Know_About_Asthma from './Know_About_Asthma';
import Tips_keep_asthma_under_control from './Tips_keep_asthma_under_control';
import Quit_Smoking_Today_Before_Its_Too_Late from './Quit_Smoking_Today_Before_Its_Too_Late';
import Pneumonia_a_common_illness_in_winters from './Pneumonia_a_common_illness_in_winters';
import Role_diet_nutrients_maintaining_healthy_lungs from './Role_diet_nutrients_maintaining_healthy_lungs';
import Types_Of_Asthma from './Types_Of_Asthma';
import Myths_About_Using_Inhalers from './Myths_About_Using_Inhalers';
import Asthma_in_adults_and_children from './Asthma_in_adults_and_children';
import Chronic_obstructive_pulmonary_disease from './Chronic_obstructive_pulmonary_disease';
import Why_asthma_worse_in_winter from './Why_asthma_worse_in_winter';
import Gallery from './Gallery';


function App() {
  <HelmetProvider>
    <Helmet>

      <meta charSet="utf-8" />
      <title>Dr Bharani Rachagulla Best Pulmonologist In Guntur</title>
      <meta property="og:title" content="Dr Bharani Rachagulla Best Pulmonologist In Guntur" />
      <meta property="og:site_name" content="Dr Bharani Rachagulla Best Pulmonologist In Guntur" />
      <meta property="og:description"
        content="Dr Bharani is a consultant, clinical and interventional pulmonologist with over 10 years of experience." />

      {/* <meta property="og:image" content="http://helphospital.in/Help%20LOGO%20(1).png" /> */}
    </Helmet>
  </HelmetProvider>
  return (
    <>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/Acute_Bronchitis" element={<Acute_Bronchitis />} />
          <Route path="/Best_Asthma_Hospital_InGuntur" element={<Best_Asthma_Hospital_InGuntur />} />
          <Route path='/Chronic_Bronchitis' element={<Chronic_Bronchitis />}></Route>
          <Route path='/Chronic_Obstructive_Pulmonary_Diseases' element={<Chronic_Obstructive_Pulmonary_Diseases />}></Route>
          <Route path='/Pneumonia' element={<Pneumonia />} />
          <Route path='/Tuberculosis' element={<Tuberculosis />} />
          <Route path='/Pulmonary_Emphysema' element={<Pulmonary_Emphysema />} />
          <Route path='/Contact' element={<Contact />} />
          <Route path='/Dr_Bharani_Rachagulla' element={<Dr_Bharani_Rachagulla />} />
          <Route path='/Why_ChooseUs' element={<Why_ChooseUs />} />
          <Route path='/Interventional_Pulmonology' element={<Interventional_Pulmonology />} />
          <Route path='/Infection_diseases' element={<Infection_diseases />} />
          <Route path='/ILD' element={<ILD />} />
          <Route path='/ALLERGY_MANAGMENT' element={<ALLERGY_MANAGMENT />} />
          <Route path='/Critical_Care' element={<Critical_Care />} />
          <Route path='/Targeted_Asthma_Therapy' element={<Targeted_Asthma_Therapy />} />
          <Route path='/Swasa_Clinic' element={<Swasa_Clinic />} />
          <Route path="/Blogs" element={<Blogs />} />
          <Route path='/Know_About_Asthma' element={<Know_About_Asthma/>}/>
          <Route path="/Tips_keep_asthma_under_control" element={<Tips_keep_asthma_under_control />} />
          <Route path="/Quit_Smoking_Today_Before_Its_Too_Late" element={<Quit_Smoking_Today_Before_Its_Too_Late />} />
          <Route path="/Pneumonia_a_common_illness_in_winters" element={<Pneumonia_a_common_illness_in_winters />} />
          <Route path="/Role_diet_nutrients_maintaining_healthy_lungs" element={<Role_diet_nutrients_maintaining_healthy_lungs />} />
          <Route path="/Types_Of_Asthma" element={<Types_Of_Asthma />} />
          <Route path="/Myths_About_Using_Inhalers" element={<Myths_About_Using_Inhalers />} />

          <Route path="/Asthma_in_adults_and_children" element={<Asthma_in_adults_and_children />} />
          <Route path="/Chronic_obstructive_pulmonary_disease" element={<Chronic_obstructive_pulmonary_disease />} />
          <Route path="/Why_asthma_worse_in_winter" element={<Why_asthma_worse_in_winter />} />
          <Route path="/Gallery" element={<Gallery />} />
          <Route path="/" element={<Home />} />

        </Routes>

        {/* <div className='right-navigation'>
          <Link to='Insurance' className='insurance_float'><img className="whatsapp_float" src='insu6.webp' alt=''></img>Insurance</Link>
        </div> */}
        <div className="GoTop"  >
          <ScrollToTop smooth style={{ backgroundColor: 'rgb(17, 112, 150)', bottom: '10px', width: '35px', height: '35px' }} />
        </div>
        <Footer />
        <div className='sbuttons' >
        <Link className='sbutton youtube' to='https://www.youtube.com/channel/UCjHux3HMMb_nIBgy_eBuS4A' title='Youtube'><i className="bi bi-youtube"></i></Link><br></br>
          <Link className='sbutton facebook' to='https://www.facebook.com/DrBharanirachagulla' title='Facebook'><i className="bi bi-facebook"></i> </Link><br></br>
          <Link className='sbutton linkedin' to='' title='Linkedin'><i className='bi bi-linkedin'></i></Link><br></br>

          <Link className='sbutton instagram' to='https://www.instagram.com/drbharanirachagulla?fbclid=IwAR0IUOd-pTwoIhxgFueYugKa9kmF6fvDIg9jGa8gsvmPEWsvuZO2UdS98kM' title='Instagram' ><i className="bi bi-instagram"></i></Link><br></br>
        </div>
      </BrowserRouter>




    </>
  );
}

export default App;
