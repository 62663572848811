import React from 'react'

const Myths_About_Using_Inhalers = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(17, 112, 150)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>Myths About Using Inhalers</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/home">Home</a></li>
                    </ul>
                </div>
            </div>

            <div className='container'>
                <div className='row'>
                    <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                        <div className="row">
                            <div className="col-lg-7 mb-2">
                                <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                                    <span style={{ color: "rgb(17, 112, 150)" }}>Myths About Using Inhalers</span>
                                </h1>
                                <p style={{ alignItems: 'justify' }}>
                                    According to the World Health Organization (WHO) Asthma Report, more than 339 million people suffer from asthma, which can cause wheezing, breathlessness, chest tightness, and coughing. Asthma is a condition in which the airways narrow, swell and produce extra mucus. This can make breathing difficult and trigger coughing, wheezing, and shortness of breath. For some people, asthma is just a minor nuisance.
                                </p>
                                <p style={{ alignItems: 'justify' }}>
                                    “taking asthma medication correctly and adhering to the treatment plan can help better management of day-to-day triggers and keep asthma in control. Inhalation therapy is the cornerstone for acute and long-term management of asthma.”
                                </p>
                                <p style={{ alignItems: 'justify' }}>
                                    Several misconceptions and wrong practices regarding the use of inhalers are prevalent in India. These affect the treatment-seeking behavior and compliance. Myths regarding inhalation treatments lead to less than optimal use of these delivery systems, which impacts the disease morbidity and mortality.
                                </p>
                            </div>
                            <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                                <img className="mt-5" style={{ height: '300px', width: '80%', borderRadius: '15px' }} id="ServicesImg" src='myths.png' alt=''></img>
                            </div>
                        </div>


                        <p style={{ alignItems: 'justify' }}>
                            There is a lack of awareness regarding the use of inhalers for the treatment. Patients in India have this misconception that regular use of inhalers develops a habit, and it becomes an addiction and will burn a hole in their pocket. It is looked upon as a cost addition to the treatment to the otherwise simple oral prescription.
                        </p>
                        <p style={{ alignItems: 'justify' }}>
                            People are also apprehensive about using it as they are suspicious of its side effects. They think steroids that are used for asthma treatment are dangerous and may cause dryness of the nose and throat. The availability of alternative therapy, i.e., oral pills, looks like a very convenient option, and the patient is assured that he has taken due treatment. The patient might find the inhaler technique difficult and not sure about the dose and medicine reaching his/ her lungs as there is no taste. Moreover, inhalers are difficult to use and carry.
                        </p>
                        <p style={{ alignItems: 'justify' }}>
                            Dr  further elaborates that there is a big social stigma attached to it. The reason is that the use of inhalers gives you a visual of a person using the device while gasping and struggling to breathe. This is hence also made a big issue if a girl to be married suffering from asthma and is using inhalers. Time constraint for educating and counseling patients also adds to the existing problems.
                        </p>
                        <p style={{ alignItems: 'justify' }}>
                            People also tend to believe that asthma is not real and it is all in the head, while the fact is emotion and stress trigger asthma. People tend to stop taking inhalers once their symptoms subside, and when they tend to develop symptoms again due to the underlying inflammation, they feel that inhalers do not work properly and are inefficient.
                        </p>
                        <p style={{ alignItems: 'justify' }}>
                            The myths amongst pediatric patients are also quite prevalent, and parents assume that using inhalers and inhaled steroids might affect the growth of a child and their intelligence level. They also believe that children with asthma should not play sports. Hence various educational programs on the management of asthma need to be organized.
                        </p>
                        <p style={{ alignItems: 'justify' }}>
                            further stresses that the patients need to understand that asthma is not a big deal. It is a disease that can be controlled, and the patient can lead a totally fulfilling life. People need to start accepting asthma and not being afraid about it. They should rather resort to using the best-in-class inhalation therapy and make the smart choice of using inhalers.
                        </p>


                    </div>
                </div>
            </div>
        </>
    )
}

export default Myths_About_Using_Inhalers