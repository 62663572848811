import React from 'react'

const Gallery = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(17, 112, 150)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>Gallery</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/home">Home</a></li>
                    </ul>
                </div>
            </div>
            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-md-3 col-sm-12 mt-2'>
                        <iframe width="100%" height="180" style={{borderRadius:'10px'}} src="https://www.youtube.com/embed/1EcPHogqPwI?si=4sKmPTaCDe9W5X5n" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                    <div className='col-md-3 col-sm-12 mt-2'>
                    <iframe width="100%" height="180" style={{borderRadius:'10px'}}  src="https://www.youtube.com/embed/wtAHrtEid2E?si=ZVSZpybUzoBkA6oN" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                    <div className='col-md-3 col-sm-12 mt-2'>
                    <iframe width="100%" height="180" style={{borderRadius:'10px'}}  src="https://www.youtube.com/embed/294m8tvFbh0?si=sLb_T5iYBM63cyf1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                    <div className='col-md-3 col-sm-12 mt-2'>
                    <iframe width="100%" height="180" style={{borderRadius:'10px'}}  src="https://www.youtube.com/embed/WTQruy3XL0U?si=D2jZHpwVt93RFa-c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-md-3 col-sm-12 mt-2'>
                    <iframe width="100%" height="180" style={{borderRadius:'10px'}}  src="https://www.youtube.com/embed/ouFbWcxxPJU?si=5m_mcEpZ9wothEoK" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                    <div className='col-md-3 col-sm-12 mt-2'>
                    <iframe width="100%" height="180" style={{borderRadius:'10px'}}  src="https://www.youtube.com/embed/8Xw6rNfneY0?si=hP4hngI8QIKgESh1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                    <div className='col-md-3 col-sm-12 mt-2'>
                    <iframe width="100%" height="180" style={{borderRadius:'10px'}}  src="https://www.youtube.com/embed/JYjeurQ9TE0?si=19mRBbPasRWfs60W" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                    <div className='col-md-3 col-sm-12 mt-2'>
                    <iframe width="100%" height="180" style={{borderRadius:'10px'}}  src="https://www.youtube.com/embed/I0uTaBgsey0?si=nMp9UgLJQIqVuIVV" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-md-3 col-sm-12 mt-2'>
                    <iframe width="100%" height="180" style={{borderRadius:'10px'}}  src="https://www.youtube.com/embed/RPHg8O3qUAI?si=asEcy0ZlMVHqI-5D" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                    <div className='col-md-3 col-sm-12 mt-2'>
                    <iframe width="100%" height="180" style={{borderRadius:'10px'}}  src="https://www.youtube.com/embed/8_-uespDSsI?si=xtwba1g69PLJc5sU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                   
                </div>
            </div>
        </>
    )
}

export default Gallery