import React from 'react'

const Why_ChooseUs = () => {
  return (
    <>
    <div style={{height:'120px'}}></div>
    <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex',backgroundColor:"rgb(17, 112, 150)",}}>
        <div className="col text-center strip1" style={{ color: 'white' }}>
          <div className="row mt-4" >
            <h1 style={{textTransform:'uppercase'}}>Why ChooseUs</h1>
          </div>
          <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
            <li ><a href="/home">Home</a></li>
           
          </ul>
        </div>
      </div>
      <div className='container'>
        <div className='row'>
        <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
        <div className="row">
          <div className="col-lg-7 mb-2">
            <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "rgb(17, 112, 150)" }}>Why Choose Shri Hospital for Pulmonary Care?</span>
            </h1>
            <p style={{textAlign:'justify'}}>
            Comprehensive Respiratory Care: At Shri Hospital, we offer a wide range of respiratory services to address various pulmonary conditions. Whether it's asthma, chronic bronchitis, lung infections, or more complex lung diseases, Dr. Bharani and his team are equipped to diagnose, treat, and manage a spectrum of respiratory issues.
            </p>
            <p style={{textAlign:'justify'}}>
            State-of-the-Art Facilities: Our hospital is well-equipped with the latest medical technology and advanced diagnostic tools to ensure accurate and efficient evaluations. We believe in harnessing the power of modern medical advancements to offer precise diagnoses and effective treatment plans.
            </p>
            <p style={{textAlign:'justify'}}>
            Patient-Centric Approach: We understand that every patient is unique and may require individualized care. Dr. Bharani and his team take the time to listen to your concerns, perform thorough evaluations, and craft personalized treatment approaches that best suit your needs. 
            </p> 
          </div>
          <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
            <img className="mt-5" style={{ width:'100%',height: '300px',borderRadius:'15px',border:'5px solid grey' }} id="ServicesImg" src='sree_hospital.jpeg' alt=''></img>
          </div>
        </div>
        <p style={{textAlign:'justify'}}>
            Multidisciplinary Team: Dr. Bharani collaborates closely with a team of healthcare professionals, including respiratory therapists, nurses, and other specialists, to ensure comprehensive and well-rounded care for our patients. 
            </p>
            <p style={{textAlign:'justify'}}>
            Emphasis on Patient Education: Education is an essential aspect of our patient care approach. We believe that informed patients are better equipped to manage their health effectively. Dr. Bharani and his team provide clear and concise explanations about diagnoses, treatment plans, and preventive measures to empower patients to take charge of their respiratory health.
            </p>
            <p style={{textAlign:'justify'}}>
            Warm and Supportive Environment: At Shri Hospital, we pride ourselves on creating a welcoming and compassionate environment for our patients. We understand that dealing with respiratory issues can be challenging, and our caring staff is here to provide the support and reassurance you need.
             </p>
            <p style={{textAlign:'justify'}}>
            Take Control of Your Respiratory Health:
                        </p>
            <p style={{textAlign:'justify'}}>
            If you or your loved ones are experiencing any respiratory concerns, do not hesitate to reach out to Dr. Bharani at Shri Hospital. Take the first step towards better respiratory health by scheduling a consultation today.            </p>

      
      </div>

        </div>
      </div>
   </>
  )
}

export default Why_ChooseUs