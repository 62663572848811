import React from 'react'

const Why_asthma_worse_in_winter = () => {
  return (
    <>
        <div style={{ height: '120px' }}></div>
        <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(17, 112, 150)", }}>
            <div className="col text-center strip1" style={{ color: 'white' }}>
                <div className="row mt-4" >
                    <h1 style={{ textTransform: 'uppercase' }}>Why asthma worse in winter</h1>
                </div>
                <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                    <li ><a href="/home">Home</a></li>
                </ul>
            </div>
        </div>

        <div className='container'>
            <div className='row'>
                <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                    <div className="row">
                        <div className="col-lg-7 mb-2">
                            <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                                <span style={{ color: "rgb(17, 112, 150)" }}>Why Asthma Worse In Winter</span>
                            </h1>
                            <p>
                            For asthma sufferers, winter can be the most difficult time of the year. Cold, dry air and sudden shifts in the weather can irritate your airways, causing you to produce more mucus. It doesn’t always help to stay indoors, as this can lead to an increase in respiratory illnesses like colds and flu.
                            </p>
                         
                            <p style={{ textAlign: 'justify' }}>
                            When you add it all up, the colder months can be a recipe for flare-ups, with severe asthma symptoms that can become uncontrolled. As a pulmonary nurse practitioner at the Temple Lung Center, I’m going to shed more light on why the cold triggers asthma symptoms and what you can do to find relief.
                            </p>
                            <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                        <span style={{ color: "rgb(52, 186, 240)" }}>Why is asthma worse in the colder months?</span>
                    </h4 >
                    <p>There are many reasons why your asthma symptoms may get worse in the winter. It’s likely due to one or more of the following:</p>
                        </div>
                        <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                            <img className="mt-5" style={{ width: '80%', height: '300px', borderRadius: '15px' }} id="ServicesImg" src='Why_Asthama_Worse_in_Winter.jpg' alt=''></img>
                        </div>
                    </div>
                    <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                        <span style={{ color: "rgb(52, 186, 240)" }}>Dry outside air</span>
                    </h4 >
                    <p>Airways in your lungs are protected by a layer of fluid. When exposed to cold air, the layer of fluid evaporates, causing your airways to become inflamed and irritated.</p>

                    <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                        <span style={{ color: "rgb(52, 186, 240)" }}>Respiratory illness</span>
                    </h4 >
                    <p>Your airways also have a protective layer of mucus. When it gets cold outside, this layer of mucus can thicken, increasing your chance of getting respiratory infections like the common cold or flu. These infections may lead to swelling and irritation of your airways, which trigger asthma symptoms.</p>

                    <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                        <span style={{ color: "rgb(52, 186, 240)" }}>Exercise</span>
                    </h4 >
                    <p>A walk in the park is healthy, right? Sure it is, but exercise requires increased lung capacity. As you walk and breathe in cold air, your airways can become restricted, triggering symptoms such as cough and breathlessness.</p>
                    <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                        <span style={{ color: "rgb(52, 186, 240)" }}>Are asthma symptoms different in the winter than in other months?</span>
                    </h4 >
                    <p>Symptoms of asthma in the colder months are the same as the symptoms you get in the warmer months. The difference is, you may notice the symptoms are worse than usual, more difficult to control or occur more often in the winter. Look for:</p>
                    <ul>
                      <li>Chest pain</li>
                      <li>Coughing</li>
                      <li>Shortness of breath</li>
                      <li>Tightness in the chest</li>
                      <li>Wheezing</li>
                    </ul>
                    <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                        <span style={{ color: "rgb(52, 186, 240)" }}>What are some tips on preventing asthma attacks triggered by cold weather?</span>
                    </h4 >
                    If you have asthma, you already know prevention is your best strategy. Go back to the basics:
                    <ul>
                      <li>Drink a lot of water, broth-based soups and decaffeinated tea to keep yourself hydrated.</li>
                      <li>Wash your hands often in soap and water to prevent respiratory illnesses such as the cold and flu.</li>
                      <li>Dress warmly when you go out. Keep a scarf, gloves and extra jacket in your car just in case.</li>
                      <li>Breathe through your nose when you’re outside. Your nasal passages warm the air before it moves into your lungs.</li>
                      <li>Get the flu vaccine, which will lower your risk of getting this year’s flu.</li>
                      <li>Carry your inhaler with you all the time.</li>
                      <li>Find alternative ways to exercise if you usually exercise outdoors. Make sure the place where you exercise has good air circulation.</li>
                      <li>If you have an indoor fireplace, try to keep it empty when not in use. Avoid outdoor firepits, or sit at a comfortable distance.</li>
                      <li>Use a humidifier indoors, especially at night when you sleep.</li>
                      <li>Keep up with your daily asthma medications and refilling them, and do not skip doses.</li>
                      <li>If you know you’re prone to winter month attacks, make a plan with your doctor.</li>
                     
                    </ul>
                    <p>If you do have an asthma attack, follow your asthma action care plan you and your provider discussed to get your symptoms under control.</p>
                    <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                        <span style={{ color: "rgb(52, 186, 240)" }}>What types of treatments may be given for uncontrolled asthma symptoms?</span>
                    </h4 >
                    <p>When asthma is at its worst, you may have additional symptoms such as feeling anxious, having an increased heart rate and rapid breathing.</p>
                    <p>You may be given treatments such as oxygen therapy and bronchodilators. These are medicines that help you breathe easier and open up your airways. You may also be given steroids to help get the inflammation in your airways under control.</p>

                    <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                        <span style={{ color: "rgb(52, 186, 240)" }}>Are there more advanced treatments for severe uncontrolled asthma symptoms?</span>
                    </h4 >
                    <p>Advanced asthma treatments include medicines called biologics, which are made from natural substances. These drugs help by reducing your body’s reaction to asthma triggers and controlling inflammation.</p>
                    <p>If your asthma is not controlled with steroids or other methods, bronchial thermoplasty may be an option. Doctors use an electrode to heat the inside of your airways. This procedure helps relax the airways to help you breathe easier.</p>
                  
                </div>
            </div>
        </div>
    </>
)
}

export default Why_asthma_worse_in_winter