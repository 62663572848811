import React from 'react'
import {
    MDBFooter,
    MDBContainer,
    MDBCol,
    MDBRow,
    MDBIcon 
  } from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <>
    
    <MDBFooter bgColor='light' className='container-fluid text-center text-lg-start text-muted mt-5 mb-2'>
      <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom' style={{backgroundColor:'rgb(17, 112, 150)',color:'snow'}}>
        <div className='me-5 d-none d-lg-block'>
          <span>Get connected with us on social networks:</span>
        </div>

        <div style={{color:'whitesmoke'}}>
          <a href='https://www.facebook.com/DrBharanirachagulla' className='me-4 text-reset' style={{color:'whitesmoke'}}>
            <MDBIcon  fab icon="bi bi-facebook"  />
            
          </a>
          <a href='/' className='me-4 text-reset'>
            <MDBIcon  fab icon='bi bi-twitter' />
          </a>
          <a href='https://www.google.com/search?q=drbharani+rachagolla&rlz=1C1JJTC_enIN1028IN1028&oq=drbharani+rachagolla&gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIJCAEQIRgKGKAB0gEOOTc2MjU2MDY4ajBqMTWoAgCwAgA&sourceid=chrome&ie=UTF-8' className='me-4 text-reset'>
            <MDBIcon fab icon='bi bi-google' />
          </a>
          <a href='https://www.instagram.com/drbharanirachagulla?fbclid=IwAR0IUOd-pTwoIhxgFueYugKa9kmF6fvDIg9jGa8gsvmPEWsvuZO2UdS98kM' className='me-4 text-reset'>
            <MDBIcon fab icon='bi bi-instagram' />
          </a>
          <a href='/' className='me-4 text-reset'>
            <MDBIcon fab icon='bi bi-linkedin' />
          </a>
          <a href='https://www.youtube.com/channel/UCjHux3HMMb_nIBgy_eBuS4A' className='me-4 text-reset'>
            <MDBIcon  fab icon='bi bi-youtube' />
          </a>
        </div>
      </section>

      <section className=''style={{backgroundColor:'rgb(17, 112, 150)',color:'snow'}}>
        <MDBContainer className='text-center text-md-start ' >
          <MDBRow className='' style={{Color:'rgb(17, 112, 150)',paddingTop:'30px'}}>
            <MDBCol md='3' lg='4' xl='3' className='mx-auto mb-4'>
              <img src='DR BHARANI Logo png1.png'  style={{width:'250px',height:'90px',borderRadius:'10px',backgroundColor:'whitesmoke'}} alt=''></img>
              <p style={{ textAlign:'center',marginTop:'10px' }}>
              Dr Bharani is a consultant, clinical and interventional pulmonologist with over 10 years of experience.
              </p>
            </MDBCol>

            <MDBCol md='2' lg='2' xl='2' className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Services</h6>
              <p>
              
                <Link to='/Acute_Bronchitis' className='text-reset'>
                Acute Bronchitis
                </Link>
              </p>
              <p>
              <Link to='/Chronic_Bronchitis' className='text-reset'>
              Chronic Bronchitis
                </Link>
              </p>
              <p>
              <Link to='/Best_Asthma_Hospital_InGuntur' className='text-reset'>
                 Asthma
                </Link>
              </p>
              <p>
              <Link to='/Chronic_Obstructive_Pulmonary_Diseases' className='text-reset'>
              COPD
                </Link>
              </p>
            </MDBCol>

            <MDBCol md='3' lg='2' xl='2' className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Useful links</h6>
              <p>
              <Link to='/Pneumonia' className='text-reset'>
              Pneumonia
                </Link>
              </p>
              <p>
              <Link to='/Pulmonary_Emphysema' className='text-reset' style={{fontSize:'16px'}}>
              Pulmonary Emphysema
                </Link>
              </p>
              <p>
              <Link to='/ILD' className='text-reset'>
              ILD
                </Link>
              </p>
              <p>
              <Link to='/Critical_Care' className='text-reset'>
              Critical Care
                </Link>
              </p>
            </MDBCol>

            <MDBCol md='4' lg='3' xl='3' className='mx-auto mb-md-0 mb-4'>
              <h6 className='text-uppercase fw-bold mb-2'>Contact</h6>
              <p>
                <MDBIcon color='secondary'className='me-2' />
                <i className="bi bi-house-door-fill"></i><span> Kakumanu Vari Thota, Vishnu Nagar, Donka Road, Mallikarjunpet, Guntur, Andhra Pradesh 522002</span>
               
              </p>
              <p>
                <MDBIcon color='secondary'  style={{color:'white'}} className='me-3' />
                <i className="bi bi-envelope-at-fill" style={{fontSize:'small'}}> drbharanirachagulla@gmail.com</i>
               
              </p>
              <p>
                <MDBIcon color='secondary'  className='me-3' /> 
                <i className="bi bi-telephone-fill"> 8978186668</i>
              </p>
              <p>
                <MDBIcon color='secondary'  className='me-3' /><i className="bi bi-phone-fill"> 08632213999</i>
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <MDBRow style={{border:'1px solid white'}}>
            <p className='text-center mt-3 mb-3'>Designed By - <span className='DartLink'><a href='http://www.dartmarketing.in/' >Dart Marketing Solutions</a></span></p>
            
          </MDBRow>
      </section>

      
    </MDBFooter>


  
    
    </>
  )
}

export default Footer