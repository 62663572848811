import React from 'react'

const Pneumonia = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(17, 112, 150)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>Pneumonia</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/home">Home</a></li>

                    </ul>
                </div>
            </div>
            <div className='container'>
                <div className='row'>
                    <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                        <div className="row">
                            <div className="col-lg-7 mb-2">
                                <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                                    <span style={{ color: "rgb(17, 112, 150)" }}>Pneumonia</span>
                                </h1>
                                <p>
                                    Pneumonia is a common and potentially serious respiratory infection affecting millions of people worldwide. It is an inflammation of the lungs, primarily caused by bacterial, viral, or fungal infections. Understanding the causes, symptoms, prevention, and management of pneumonia is essential for individuals, caregivers, and healthcare professionals to promote early detection and effective treatment.            </p>

                                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                                    <span style={{ color: "rgb(52, 186, 240)" }}>What is Pneumonia?</span>
                                </h4 >
                                <ul>
                                    <li>Definition of pneumonia and its types (community-acquired, hospital-acquired, aspiration)</li>
                                    <li>Common pathogens responsible for pneumonia</li>
                                    <li>Prevalence and impact on global health</li>
                                </ul>

                            </div>
                            <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                                <img className="mt-5" style={{ height: '300px',width:'80%', borderRadius: '15px' }} id="ServicesImg" src='PNEUMONIA.jpg' alt=''></img>
                            </div>
                        </div>

                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}> Pneumonia Causes and Risk Factors:</span>
                        </h4 >
                        <ul>
                            <li>
                                Bacterial pneumonia (Streptococcus pneumoniae, Haemophilus influenzae, etc.).
                            </li>
                            <li>Viral pneumonia (influenza, respiratory syncytial virus, COVID-19).</li>
                            <li>Fungal pneumonia (Pneumocystis jirovecii, Histoplasma capsulatum).</li>
                            <li>Factors that increase susceptibility (age, weakened immune system, chronic illnesses).</li>
                        </ul>

                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}>Pneumonia Symptoms:</span>
                        </h4 >
                        <ul>
                            <li>Common signs of pneumonia (cough, fever, chills, chest pain).</li>
                            <li>Difficulty breathing and shortness of breath.</li>
                            <li>Fatigue, weakness, and confusion.</li>
                            <li>Symptoms in children and the elderly.</li>
                        </ul>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}>Diagnosing Pneumonia:</span>
                        </h4 >
                        <ul>
                            <li>Physical examination and medical history.</li>
                            <li>Chest X-rays and imaging techniques.</li>
                            <li>Sputum and blood tests to identify the causative agent.</li>
                        </ul>

                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}>Preventing Pneumonia:</span>
                        </h4 >
                        <ul>
                            <li>Vaccinations for bacterial pneumonia (pneumococcal, Haemophilus influenzae type b).</li>
                            <li>Influenza vaccine and its role in preventing viral pneumonia.</li>
                            <li>Promoting good hygiene practices (handwashing, respiratory etiquette).</li>
                            <li>Smoking cessation and its impact on pneumonia risk.</li>
                        </ul>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}>Pneumonia Treatment:</span>
                        </h4 >
                        <ul>
                            <li>Antibiotics for bacterial pneumonia and their appropriate use.</li>
                            <li>Antiviral medications for viral pneumonia.</li>
                            <li>Antifungal drugs for fungal pneumonia.</li>
                            <li>Hospitalization and supportive care in severe cases.</li>
                        </ul>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}>At-Home Care for Pneumonia:</span>
                        </h4 >
                        <ul>
                            <li>Rest, hydration, and proper nutrition.</li>
                            <li>Over-the-counter medications for symptom relief.</li>
                            <li>When to seek medical attention and possible complications.</li>
                        </ul>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}>Pneumonia and COVID-19:</span>
                        </h4 >
                        <ul>
                            <li>Understanding the connection between pneumonia and COVID-19.</li>
                            <li>How COVID-19 pneumonia differs from other types of pneumonia.</li>
                            <li>Impact on public health and lessons learned from the pandemic.</li>
                        </ul>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}>Conclusion</span>
                        </h4 >
                        <p>Pneumonia is a serious respiratory infection that can affect people of all ages and backgrounds. Understanding the various causes, symptoms, and preventive measures is crucial in reducing its incidence and severity. Early detection, prompt treatment, and preventive strategies, such as vaccinations and maintaining good hygiene practices, play a significant role in combating pneumonia. By staying informed and seeking appropriate medical care, individuals can protect themselves and their loved ones from the potential consequences of pneumonia.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Pneumonia