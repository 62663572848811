import React from 'react'

const Chronic_Obstructive_Pulmonary_Diseases = () => {
  return (
    <>
      <div style={{ height: '120px' }}></div>
      <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(17, 112, 150)", }}>
        <div className="col text-center strip1" style={{ color: 'white' }}>
          <div className="row mt-4" >
            <h1 style={{ textTransform: 'uppercase' }}>Chronic Obstructive Pulmonary Diseases (COPD)</h1>
          </div>
          <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
            <li ><a href="/home">Home</a></li>

          </ul>
        </div>
      </div>
      <div className='container'>
        <div className='row'>
          <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
            <div className="row">
              <div className="col-lg-7 mb-2">
                <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                  <span style={{ color: "rgb(17, 112, 150)" }}>Chronic Obstructive Pulmonary Diseases(COPD)</span>
                </h1>
                <p>
                  Chronic Obstructive Pulmonary Disease (COPD) is a progressive respiratory disorder that affects millions of people worldwide. It primarily involves chronic bronchitis and emphysema, leading to long-term airflow limitation and breathing difficulties. Understanding COPD, its causes, symptoms, and management strategies are crucial for individuals affected by the disease, their caregivers, and the general public.            </p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                  <span style={{ color: "rgb(52, 186, 240)" }}>What is COPD?</span>
                </h4 >
                <ul>
                  <li>
                    Definition of COPD and its classification.</li>
                  <li>Causes and risk factors (smoking, environmental exposures, genetic predisposition).</li>
                  <li>Prevalence and global impact of COPD.</li>
                </ul>

              </div>
              <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                <img className="mt-5" style={{width:'80%', height: '300px', borderRadius: '15px' }} id="ServicesImg" src='COPD.jpg' alt=''></img>
              </div>
            </div>

            <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "rgb(52, 186, 240)" }}>COPD Symptoms:</span>
            </h4 >
            <ul>
              <li>Chronic coughing and mucus production</li>
              <li>Shortness of breath (dyspnea)</li>
              <li>Wheezing and chest tightness</li>
              <li>Fatigue and unintended weight loss</li>
              <li>Exacerbations and their warning signs</li>
            </ul>

            <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "rgb(52, 186, 240)" }}>Diagnosis and Staging:</span>
            </h4 >
            <ul>
              <li>Importance of early diagnosis.</li>
              <li>Pulmonary function tests (spirometry) and other diagnostic methods.</li>
              <li>GOLD (Global Initiative for Chronic Obstructive Lung Disease) staging system.</li>
            </ul>
            <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "rgb(52, 186, 240)" }}>Understanding Chronic Bronchitis:</span>
            </h4 >
            <ul>
              <li>Definition and pathophysiology.</li>
              <li>Role of inflammation and mucus production.</li>
              <li>Treating chronic bronchitis and managing exacerbations.</li>
            </ul>
            <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "rgb(52, 186, 240)" }}>Managing COPD:</span>
            </h4 >
            <ul>
              <li>Medications for symptom relief and disease modification</li>
              <li>Inhalers and nebulizers: how to use them effectively</li>
              <li>Pulmonary rehabilitation programs</li>
              <li>Oxygen therapy and its benefits</li>
            </ul>
            <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "rgb(52, 186, 240)" }}>Preventing COPD:</span>
            </h4 >
            <ul>
              <li>Promoting awareness and education</li>
              <li>Reducing exposure to risk factors (smoking, air pollution, occupational hazards)</li>
              <li>Immunizations to prevent respiratory infections</li>
            </ul>
            <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "rgb(52, 186, 240)" }}> COPD and Mental Health:</span>
            </h4 >
            <ul>
              <li>Addressing anxiety and depression related to COPD.</li>
              <li>Support groups and resources for coping with the emotional impact.</li>
            </ul>
            <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "rgb(52, 186, 240)" }}>Conclusion</span>
            </h4 >
            <p>COPD is a complex and life-changing condition, but with the right knowledge and management, individuals can lead fulfilling lives. By promoting awareness, adopting healthy habits, and seeking proper medical care, COPD patients can improve their quality of life and reduce the disease's impact. Early diagnosis, lifestyle changes, and staying informed about new treatments and research developments are essential for tackling COPD effectively. Remember, seeking professional medical advice and support is crucial in managing this chronic lung disease.</p>
          </div>

        </div>
      </div>
    </>
  )
}

export default Chronic_Obstructive_Pulmonary_Diseases