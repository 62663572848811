import Carousel from 'react-bootstrap/Carousel';
import React from 'react'
import Testmonialss from './Testmonialss';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import './App.css'
import { Link } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper/modules';


const Home = () => {
    <HelmetProvider>
        <Helmet>

            <meta charSet="utf-8" />
            <title>Dr Bharani Rachagulla Best Pulmonologist In Guntur</title>
            <meta property="og:title" content="Dr Bharani Rachagulla Best Pulmonologist In Guntur" />
            <meta property="og:site_name" content="Dr Bharani Rachagulla Best Pulmonologist In Guntur" />
            <meta property="og:description"
                content="Dr Bharani is a consultant, clinical and interventional pulmonologist with over 10 years of experience." />

            {/* <meta property="og:image" content="http://helphospital.in/Help%20LOGO%20(1).png" /> */}
        </Helmet>
    </HelmetProvider>

    return (
        <>
            <div style={{ paddingTop: '130px' }}>
                <Carousel className=" homeclass" variant='dark' indicators={false} controls={false} pause={false} >
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="newweb_01.png"
                            alt="First slide"

                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="newweb_02.png"
                            alt="First slide"

                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="newweb_03.png"
                            alt="First slide"

                        />
                    </Carousel.Item>
                </Carousel>
            </div>

            {/* Best Pulmonologist in Guntur */}

            <div className='container'>
                <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginTop: '30px' }}>
                    <span style={{ color: "rgb(17, 112, 150)" }}>Dr Bharani Rachagulla </span>
                </h1>
                <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginTop: '10px' }}>
                    <span style={{ color: "rgb(17, 112, 150)" }}>Best Pulmonologist In Guntur</span>
                </h1>
                <div  >
                    <p style={{ textAlign: 'justify' }}>
                        At Shri Hospital, we are proud to have Dr. Bharani, a highly esteemed and experienced pulmonologist, as a vital part of our medical team. With a deep-rooted passion for respiratory care and an unwavering commitment to patient well-being, Dr. Bharani is recognized as one of the finest pulmonologists in Guntur.
                    </p>
                    <p><b>Meet Dr. Bharani - Your Trusted Respiratory Care Specialist:</b></p>
                    <p style={{ textAlign: 'justify' }}>
                        Dr. Bharani brings a wealth of expertise and knowledge in the field of pulmonology. Her years of medical training, coupled with a genuine dedication to patient care, make her a trusted name in the medical community. She is committed to providing the highest quality of medical services to patients of all ages and backgrounds, ensuring that everyone receives the personalized care they deserve.
                    </p>
                </div>
            </div>

            {/* heading..... */}
            <div>
                <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginTop: '30px' }}>
                    <span style={{ color: "rgb(17, 112, 150)" }}>Centres Of Excellence</span>
                </h1>
            </div>



            {/* cards........ */}

            <div className='container'>
                <div className='row text-center'>
                    <div className='col-lg-6 col-md-12 col-sm-12'>
                        <div className='card text-center my-3' style={{ boxShadow: 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px', borderRadius: '10px' }}>
                            <div className='card-body '>
                                <img src='apudham.png' alt='' style={{ width: '50%', height: '20%', borderRadius: '5px', marginTop: '-10px' }}></img>
                                <h3 style={{ color: 'rgb(17, 112, 150)' }}>Targeted Asthma Therapy</h3>
                                <p>
                                    Aapudam Asthma is a cutting-edge targeted asthma therapy program developed by Dr. Bharani, a renowned expert in the field of respiratory medicine. With a deep understanding of the challenges faced by asthma patients, Dr. Bharani's revolutionary approach focuses on personalized, precision-based treatment strategies                                    </p>
                                <Link to='/Targeted_Asthma_Therapy'><Button style={{ backgroundColor: 'rgb(52, 186, 240)' }}>Know More</Button></Link>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-12 col-sm-12'>
                        <div className='card text-center my-3'>
                            <div className='card-body' style={{ boxShadow: 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px', borderRadius: '10px' }}>
                                <img src='swasa.png' alt='' style={{ width: '50%', height: '20%', borderRadius: '5px', marginTop: '-10px' }}></img>
                                <h3 style={{ color: 'rgb(17, 112, 150)' }}>Swasa Clinic</h3>
                                <p>
                                    Swasa, meaning "breath" in Sanskrit, is a sanctuary of healing curated by Dr. Bharani, a distinguished name in the realm of respiratory medicine. Dr. Bharani's unwavering commitment to respiratory health has culminated in the creation of Swasa Clinic, a center dedicated to serving individuals with respiratory conditions and facilitating their journey to wellness.                                    </p>
                                <Link to='/Swasa_Clinic'><Button style={{ backgroundColor: 'rgb(52, 186, 240)' }}>Know More</Button></Link>
                            </div>
                        </div>
                    </div>
                </div>
                {/* second card row */}
                <div className='row text-center'>

                    <div className='col-lg-6 col-md-12 col-sm-12'>
                        <div className='card text-center my-3' style={{ boxShadow: 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px', borderRadius: '10px' }}>
                            <div className='card-body'>
                                <img src='WhatsApp Image 2023-08-05 at 11.54.37 AM (4).jpeg' alt='' style={{ width: '50%', height: '20%', borderRadius: '5px', marginTop: '-10px' }}></img>
                                <h3 className='mt-1' style={{ color: 'rgb(17, 112, 150)' }}>Interventional Pulmonology</h3>
                                <p >Interventional Pulmonology is the new field in pulmonary medicine that utilizes the Bronchoscopy and other tools to diagnose and treat lung diseases. Now this facility is available at SHRI Hospital Guntur.</p>
                                <Link to='/Interventional_Pulmonology'><Button style={{ backgroundColor: 'rgb(52, 186, 240)' }}>Know More</Button></Link>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-12 col-sm-12'>
                        <div className='card text-center my-3' style={{ boxShadow: 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px', borderRadius: '10px' }}>
                            <div className='card-body'>
                                <img src='COPD.jpg' alt='' style={{ width: '70%', height: '100%', borderRadius: '5px' }}></img>
                                <h3 style={{ color: 'rgb(17, 112, 150)' }}>Chronic Obstructive Pulmonary Disease (COPD)</h3>
                                <p>Chronic Obstructive Pulmonary Disease (COPD) is a progressive respiratory disorder that affects millions of people worldwide. It primarily involves chronic bronchitis and emphysema, leading to long-term airflow limitation and breathing difficulties.</p>
                                <Link to='/Chronic_Obstructive_Pulmonary_Diseases'><Button style={{ backgroundColor: 'rgb(52, 186, 240)' }}>Know More</Button></Link>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {/* <div className='container mt-5 p-3' style={{justifyContent:'center'}}>
                <div className='row'>
                    <div className='col-lg-3 col-md-6 col-sm-12 mb-5'>
                        <div className='cards' style={{ height: '250px' }}>
                                
                                   <div className='images'>
                                        <img src='acute.jpg' alt='' style={{width:'100%',borderRadius:'18px 18px 0px 0px',height:'200px'}}></img>
                                   </div>
                                   <div className='heading' >
                                        <h1 className='text-center mt-4' style={{color:'black',fontSize:'15px'}}><b>Acute Bronchitis</b></h1>
                                   </div>
                            
                            <div className='overlay'>
                                <h3 className="card-title text-center">Acute Bronchitis</h3>
                                <p className="text-center" style={{ fontSize: '16px' }}>It is a common respiratory condition that affects millions of people each year </p> 
                                <Link to='/Acute_Bronchitis' className='text-center'><Button style={{ backgroundColor: ' rgb(52, 186, 240)', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}  >Know More</Button></Link>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12 mb-5'>
                        <div className='cards' style={{ height: '250px' }}>
                                
                                   <div className='images'>
                                        <img src='CHRONIC_BRONCHITIS.jpg' alt='' style={{width:'100%',borderRadius:'18px 18px 0px 0px',height:'200px'}}></img>
                                   </div>
                                   <div className='heading' >
                                        <h1 className='text-center mt-4' style={{color:'black',fontSize:'15px'}}><b>Chronic Bronchitis</b></h1>
                                   </div>
                            
                            <div className='overlay'>
                                <h3 className="card-title text-center">Chronic Bronchitis</h3>
                                <p className="text-center" style={{ fontSize: '16px' }}>It is a long-term respiratory condition that falls under the umbrella term Chronic Obstructive Pulmonary Disease (COPD).</p> 
                                <Link to='/Chronic_Bronchitis' className='text-center'><Button style={{ backgroundColor: ' rgb(52, 186, 240)', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}  >Know More</Button></Link>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12 mb-5'>
                        <div className='cards' style={{ height: '250px' }}>
                                
                                   <div className='images'>
                                        <img src='COPD.jpg' alt='' style={{width:'100%',borderRadius:'18px 18px 0px 0px',height:'200px'}}></img>
                                   </div>
                                   <div className='heading' >
                                        <h1 className='text-center mt-2' style={{color:'black',fontSize:'15px'}}><b>Chronic Obstructive Pulmonary Diseases(COPD)</b></h1>
                                   </div>
                            
                            <div className='overlay'>
                                <h3 className="card-title text-center">COPD</h3>
                                <p className="text-center" style={{ fontSize: '16px' }}> It primarily involves chronic bronchitis and emphysema, leading to long-term airflow limitation and breathing difficulties.</p> 
                                <Link to='/Chronic_Obstructive_Pulmonary_Diseases' className='text-center'><Button style={{ backgroundColor: ' rgb(52, 186, 240)', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}  >Know More</Button></Link>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12 mb-5'>
                        <div className='cards' style={{ height: '250px' }}>
                                
                                   <div className='images'>
                                        <img src='asthma1.jpg' alt='' style={{width:'100%',borderRadius:'18px 18px 0px 0px',height:'200px'}}></img>
                                   </div>
                                   <div className='heading' >
                                        <h1 className='text-center mt-3' style={{color:'black',fontSize:'15px'}}><b>Asthma</b></h1>
                                   </div>
                            
                            <div className='overlay'>
                                <h3 className="card-title text-center">Asthma</h3>
                                <p className="text-center" style={{ fontSize: '16px' }}>Asthma is a chronic respiratory disorder characterized by inflammation and narrowing of the airways.</p> 
                                <Link to='/Best_Asthma_Hospital_InGuntur' className='text-center'><Button style={{ backgroundColor: ' rgb(52, 186, 240)', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}  >Know More</Button></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container p-3' style={{justifyContent:'center'}}>
                <div className='row'>
                    <div className='col-lg-3 col-md-6 col-sm-12 mb-5'>
                        <div className='cards' style={{ height: '250px' }}>
                                
                                   <div className='images'>
                                        <img src='PNEUMONIA.jpg' alt='' style={{width:'100%',borderRadius:'18px 18px 0px 0px',height:'200px'}}></img>
                                   </div>
                                   <div className='heading' >
                                        <h1 className='text-center mt-4' style={{color:'black',fontSize:'15px'}}><b>Pneumonia</b></h1>
                                   </div>
                            
                            <div className='overlay'>
                                <h3 className="card-title text-center">Pneumonia</h3>
                                <p className="text-center" style={{ fontSize: '16px' }}>Pneumonia is a common and potentially serious respiratory infection affecting millions of people worldwide.</p> 
                                <Link to='/Acute_Bronchitis' className='text-center'><Button style={{ backgroundColor: ' rgb(52, 186, 240)', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}  >Know More</Button></Link>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12 mb-5'>
                        <div className='cards' style={{ height: '250px' }}>
                                
                                   <div className='images'>
                                        <img src='TUBERCULOSIS(TB).jpg' alt='' style={{width:'100%',borderRadius:'18px 18px 0px 0px',height:'200px'}}></img>
                                   </div>
                                   <div className='heading' >
                                        <h1 className='text-center mt-4' style={{color:'black',fontSize:'15px'}}><b>Tuberculosis(TB)</b></h1>
                                   </div>
                            
                            <div className='overlay'>
                                <h3 className="card-title text-center">Tuberculosis(TB)</h3>
                                <p className="text-center" style={{ fontSize: '16px' }}>Tuberculosis (TB) is a highly infectious disease caused by Mycobacterium tuberculosis bacteria.</p> 
                                <Link to='/Tuberculosis' className='text-center'><Button style={{ backgroundColor: ' rgb(52, 186, 240)', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}  >Know More</Button></Link>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12 mb-5'>
                        <div className='cards' style={{ height: '250px' }}>
                                
                                   <div className='images'>
                                        <img src='PULMONARY_EMPHYSEMA.jpg' alt='' style={{width:'100%',borderRadius:'18px 18px 0px 0px',height:'200px'}}></img>
                                   </div>
                                   <div className='heading' >
                                        <h1 className='text-center mt-3' style={{color:'black',fontSize:'15px'}}><b>Pulmonary Emphysema</b></h1>
                                   </div>
                            
                            <div className='overlay'>
                                <h3 className="card-title text-center">Pulmonary Emphysema</h3>
                                <p className="text-center" style={{ fontSize: '16px' }}>It is a chronic and progressive lung condition that falls under the broader term Chronic Obstructive Pulmonary Disease (COPD).</p> 
                                <Link to='/Pulmonary_Emphysema' className='text-center'><Button style={{ backgroundColor: ' rgb(52, 186, 240)', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}  >Know More</Button></Link>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12 mb-5'>
                        <div className='cards' style={{ height: '250px' }}>
                                
                                   <div className='images'>
                                        <img src='WhatsApp Image 2023-08-05 at 11.54.37 AM (3).jpeg' alt='' style={{width:'100%',height:'210px',borderRadius:'18px 18px 0px 0px'}}></img>
                                   </div>
                                   <div className='heading' >
                                        <h1 className='text-center mt-2' style={{color:'black',fontSize:'15px'}}><b>Infection Diseases</b></h1>
                                   </div>
                            
                            <div className='overlay'>
                                <h3 className="card-title text-center">Infection Diseases</h3>
                                <p className="text-center" style={{ fontSize: '16px' }}>It is a possibly serious illness that influences the lungs.</p> 
                                <Link to='/Infection_diseases' className='text-center'><Button style={{ backgroundColor: ' rgb(52, 186, 240)', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}  >Know More</Button></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* ABOUT DOCTOR MEET OUR EXPERT */}
            <div className="container mb-5" data-aos="zoom-in-right" data-aos-duration="2000">
                <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', }}>
                    <span style={{ color: "rgb(17, 112, 150)" }}>Meet Our Expert</span>
                </h1>
                <div className="row">
                    <div className="col-lg-8 mb-2">
                        <p>
                            <strong>
                                <br />
                                Dr Bharani Rachagulla<br />
                            </strong>
                        </p>
                        <p style={{ textAlign: 'justify', fontSize: '15px' }}>
                            Dr Bharani is a consultant, clinical and interventional pulmonologist with over 10 years of experience.Dr Bharani has completed MBBS at KIMS Amalapuram, East Godavari.She completed DTCD at Guntur Medical College.She has done DNB (Pulmonary Medicine) and Fellow in Interventional Pulmonology from Yashoda Hospital, Somajiguda,Hyderabad.Dr Bharani has undergone extensive training and acquired the skill to perform Bronchscopy which includes flexible bronchoscopy, transbronchial lung biopsy, Endobronchial Biospsy Endobronchial ultrasound (EBUS), both linear and Radial Fever Thoracoscopy Foreign body extraction,Indwelling pleural catheter.
                        </p>

                        <p style={{ textAlign: 'justify', fontSize: '15px' }}>She has training in the management of sleep disorder such as polysomnography , Positive airway pressure therapy in OSA, COPD, obesity hypoventilation syndrome, neuromuscular disorders and all other sleep disordered breathing.</p>

                        {/* <p style={{ textAlign: 'justify',fontSize:'14px' }}>
            Her Special interest in the field of infertility made her pursue <b>FNB Reproductive Medicine</b>, at the prestigious <b>Madras Medical Mission Hospital Chennai </b>for a period of 2 years. During her FNB, she has vast Exposure to different kinds of fertility issues. With an aim of providing the best, up-to-date fertility treatments to patients of all Socio-Economic Status, she has started Bhavya IVF & Fertility, a state of art IVF center in Guntur.
            </p>
            <p style={{ textAlign: 'justify',fontSize:'14px' }}>
            "Our main motto is to see happiness in the faces of couples having trouble in conceiving."
                        </p>
            {/* <a href='/Contact' ><button style={{ alignItems: "right", backgroundColor: "#9932CC", color: 'white', marginBottom: '10px' }}>Read More</button></a> */}
                    </div>
                    <div style={{ textAlign: 'center' }} className="col-lg-4 mt-5">
                        <img style={{ width: "250px", height: '250px', boxShadow: '10px 10px grey' }} src='1689136434540.jpg' alt=''></img>
                    </div>
                </div>



            </div>


            {/* testmonials...... */}

            <div className='container' data-aos="zoom-in" data-aos-duration="3200">
                <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginTop: '30px' }}>
                    <span style={{ color: "rgb(17, 112, 150)" }}>Testmonials</span>
                </h1>
                <p style={{ textAlign: 'center' }}>Our team of experienced and accomplished doctors always give their best in providing ethical, modern and evidence based patient-centric treatment and always ensure health and safety of our patients.</p>
            </div>
            <div data-aos="zoom-in-up" data-aos-duration="3200" >
                <Testmonialss />
            </div>

            {/* youtube videos */}


            <div className='container swip mt-5' style={{ marginBottom: '50px' }}>
                <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginTop: '30px' }}>
                    <span style={{ color: "rgb(17, 112, 150)" }}>Healthy Tips</span>
                </h1>
                {/* <Swiper
                        modules={[Navigation, Pagination, Scrollbar,Autoplay]}
                        spaceBetween={30}
                        slidesPerView={2}
                        breakpoints= {{
                            320: {
                                slidesPerView: 1,
                              
                               spaceBetween:40
                              },
                            640: {
                              slidesPerView: 2,
                              spaceBetween: 40,
                            },
                            768: {
                              slidesPerView: 2,
                              spaceBetween: 40,
                            },
                            1024: {
                              slidesPerView: 3,
                              
                        }
                    }}
                        pagination={{ clickable: true }}
                        
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => console.log(swiper)}
                        
                    >
                        <SwiperSlide className='carded w-25 p-3'>
                            
                        <iframe width="320" height="215" src="https://www.youtube.com/embed/RPHg8O3qUAI?si=IBYiBS9zhY8GgR_y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            
                        </SwiperSlide>
                        <SwiperSlide className='carded w-25 p-3'>
                        <iframe width="320" height="215" src="https://www.youtube.com/embed/I0uTaBgsey0?si=2ACbsfvzg5i1yiF3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </SwiperSlide>
                        <SwiperSlide className='carded w-25 p-3'>
                        <iframe width="320" height="215" src="https://www.youtube.com/embed/8_-uespDSsI?si=9g2kAsdJF-xkHdl_" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </SwiperSlide>

                      

                    </Swiper> */}
                <div className='row mt-3'>
                    <div className='col-lg-4 col-sm-12 mt-2 mb-2' >
                        <iframe style={{ width: '100%', height: '200px' }} src="https://www.youtube.com/embed/8_-uespDSsI?si=ELGGpKGqFONf8-ps" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                    <div className='col-lg-4 col-sm-12 mt-2 mb-2'  >
                        <iframe style={{ width: '100%', height: '200px' }} src="https://www.youtube.com/embed/RPHg8O3qUAI?si=XZo7ESRCio7JcNDO" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                    <div className='col-lg-4 col-sm-12 mt-2 mb-2' >
                        <iframe style={{ width: '100%', height: '200px' }} src="https://www.youtube.com/embed/I0uTaBgsey0?si=XRmD6FQFv3rt2vqy" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                </div>

            </div>


            {/* Events and news..... */}
            <div className='container mb-4' id="events" data-aos="zoom-in" data-aos-duration="2000">
                <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginTop: '30px' }}>
                    <span style={{ color: "rgb(17, 112, 150)" }}> Latest Blogs</span>
                </h1>
            </div>
            <div className='container text-center' data-aos="zoom-in-right" data-aos-duration="2000" style={{ alignItems: 'center', paddingLeft: '60px', justifyContent: 'center' }}>
                <div className='row' style={{ justifyContent: 'center' }}>
                    <div className='col mb-2'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="Why_Asthama_Worse_in_Winter.jpg" alt='' />
                            <Card.Body>
                                <Card.Title>Why Asthma Worse In Winter</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/Why_asthma_worse_in_winter'>Know More</Link>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className='col mb-2'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="AsthmaBlog.png" alt='' />
                            <Card.Body>
                                <Card.Title>Know About Asthma</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/Know_About_Asthma'>Know More</Link>
                            </Card.Body>
                        </Card>

                    </div>
                    <div className='col mb-2'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="TIPSTOASTHMA.png" alt='' />
                            <Card.Body>
                                <Card.Title>Tips To Keep Your Asthma Under Control</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/Tips_keep_asthma_under_control'>Know More</Link>
                            </Card.Body>
                        </Card>
                    </div>

                </div>
            </div>

            {/* Insurance.... */}

            {/* <div class="right-navigation py-5">
                <button class="r-n-title1" onClick={()=>setSlide(true)} >Insurance</button>
              { slide && <div class="sidenav" id="mySidenav" style={{ minHeight: '260px',backgroundColor: 'white', transition: 'all ease .3s',top:'0' ,padding: '5px',textAlign: 'center',borderRadius:'5px'}}>
                    <div class="loactios-close" style={{position:"initial"}} onClick={()=>setSlide(false)}>X</div>
                    <div class="location-info">
                        <div class="location-title" style={{marginTop:'-25px'}}>
                            Available Insurances
                        </div>
                        <div class="row py-3">
                            <div class="col-md-3 col-3">
                                <img src="ABH.png" alt="image" />
                            </div>
                            <div class="col-md-3 col-3">
                                <img src="FGI.png" alt="image" />
                            </div>

                            <div class="col-md-3 col-3">
                                <img src="FHPL.png" alt="image" />
                            </div>

                            <div class="col-md-3 col-3">
                                <img src="GD.png" alt="image" />
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-3 col-3">
                                <img src="GH.png" alt="image" />
                            </div>
                            <div class="col-md-3 col-3">
                                <img src="HI.png" alt="image" />
                            </div>

                            <div class="col-md-3 col-3">
                                <img src="ICICI.png" alt="image" />
                            </div>

                            <div class="col-md-3 col-3">
                                <img src="IFFCO.png" alt="image" />
                            </div>
                        </div>
                    </div>
                    <div class="location-info">
                        <div class="row">
                            <div class="col-md-4 col-4">
                                <img src="MA.png" alt="image" />
                            </div>
                            <div class="col-md-4 col-4">
                                <img style={{height:'50px',width:'100px'}}  src="MC.png" alt="image" />
                            </div>

                            {/* <div class="col-md-3 col-3">
                                <img src="MDI.png" alt="image" />
                            </div> */}

            {/* <div class="col-md-4 col-4">
                                <img style={{height:'70px',width:'70px'}}  src="MHI.png" alt="image" />
                            </div>
                        </div>
                    </div>
                    <div class="location-info">
                        <div class="row">
                            <div class="col-md-3 col-3">
                                <img style={{height:'50px',width:'50px'}} src="NABH.png" alt="image" />
                            </div>
                            <div class="col-md-3 col-3">
                                <img src="RGI.png" alt="image" />
                            </div>

                            <div class="col-md-3 col-3">
                                <img src="RHI.png" alt="image" />
                            </div>

                            <div class="col-md-3 col-3">
                                <img src="STAR.png" alt="image" />
                            </div>
                        </div>
                    </div>
                    <div class="location-info">
                        <div class="row">
                            <div class="col-md-4 col-4">
                                <img style={{height:'50px',width:'110px'}}  src="SW.png" alt="image" />
                            </div>
                            <div class="col-md-4 col-4">
                                <img src="UHC.png" alt="image" />
                            </div>

                            <div class="col-md-4 col-4">
                                <img src="VH.png" alt="image" />
                            </div>

                           
                        </div>
                    </div>
                </div>}
            </div> */}


            {/* * book appointment pop up* */}


        </>

    )

}

export default Home