import React, { useRef, useState } from 'react'
import emailjs from 'emailjs-com'
import { Link } from 'react-router-dom'



const Contact = () => {
  const form = useRef()

  const [data, updateData] = useState({
    Name: "",
    Email: '',
    Number: '',
    Subject: '',
    Message: ''
  })
  const { Name, Email, Number, Subject, Message } = data;

  const changeHandler = e => {
    updateData({ ...data, [e.target.name]: [e.target.value] })

  }
  const submitHandler = e => {
    e.preventDefault();

    emailjs.sendForm('service_yb4ra2b', 'template_oeazm4o', e.target, '7Y-oXT2ydz4Kin8Jb')
      .then((result) => {
        alert("message Sent Succesfully")
        updateData({
          Name: "",
          Email: "",
          Number: "",
          Subject: '',
          Message: ''

        })

      }, (error) => {
        console.log(error.text);
      });

  }


  return (
    <>
      <div style={{ height: '120px' }}></div>
      <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(17, 112, 150)", }}>
        <div className="col text-center strip1" style={{ color: 'white' }}>
          <div className="row mt-4" >
            <h1 style={{ textTransform: 'uppercase' }}>CONTACT US</h1>
          </div>
          <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
            <li><Link to='/Home'>Home</Link></li>

          </ul>
        </div>
      </div>



      <div className="container text-center con my-3 mt-5 mb-3" >
        <h3 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "black" }}><b>Don't Hesitate To Contact with Us</b></span>
        </h3>
        <div className="row justify-content-center">
          <div className="card my-5" style={{ width: '18rem', margin: '15px', color: 'white', backgroundColor: 'rgb(17, 112, 150)', border: '4px solid #fff', boxShadow: '0 0 20px #6b6a6a', borderRadius: '10px' }}>
            <div className="card-body">
              <h5 className="card-title mb-3">Address</h5>
              <p className="card-text mt-2 mb-4"> Kakumanu Vari Thota, Vishnu Nagar, Donka Road, Mallikarjunpet, Guntur, Andhra Pradesh 522002</p>
              <a href="https://www.facebook.com/DrBharanirachagulla" className="btn card-link"><i style={{ color: 'white' }} className="bi bi-facebook"></i></a>
              <a href="/Home" className="btn card-link"><i style={{ color: 'white' }} className="bi bi-twitter"></i></a>
            </div>
          </div>
          <div className="card my-5 " style={{ width: '18rem', margin: '15px', backgroundColor: 'rgb(17, 112, 150)', color: 'whitesmoke', border: '4px solid #fff', boxShadow: '0 0 20px #6b6a6a', borderRadius: '10px' }}>
            <div className="card-body">
              <h5 className="card-title">Contact Details</h5>
              <h6>Dr Bharani Rachagulla</h6>
              <h6>Mobile Number</h6>
              <p className="card-text" style={{ fontSize: '15px' }}>8978186668</p>
              <a href="https://www.youtube.com/channel/UCjHux3HMMb_nIBgy_eBuS4A" className="btn card-link"><i style={{ color: 'white' }} className="bi bi-youtube"></i></a>
              <a href="https://www.instagram.com/drbharanirachagulla?fbclid=IwAR0IUOd-pTwoIhxgFueYugKa9kmF6fvDIg9jGa8gsvmPEWsvuZO2UdS98kM" className="btn card-link"><i style={{ color: 'white' }} className="bi bi-instagram"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div >
        <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "rgb(17, 112, 150)" }}>MESSAGE US</span>
        </h1>
        <h3 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "black" }}><b>Drop Us Message For Any Query</b></span>
        </h3>

        {/* form submission .......*/}
        <form ref={form} onSubmit={submitHandler}>
          <div className="container border w-100 m-auto p-3 " style={{ borderRadius: '20px' }}  >
            <div className="row" >
              <div className="col-lg-6  my-3 ">
                <div className="form-group ">
                  <input type="text" value={Name} onChange={changeHandler} className="form-control" title='' placeholder="Your Name" name="Name" required="Username is Required..." message="Username must be atmost 30 characters long..." style={{ padding: '10px' }} />
                  <div className="help-block with-errors"></div>
                </div>
              </div>
              <div className="col-lg-6 my-3 ">
                <div className="form-group">
                  <input type="email" value={Email} onChange={changeHandler} className="form-control" title='' placeholder="Your Email Address" name="Email" id="email" required="Email is required" data-error="Please enter your email" fdprocessedid="p2hd6j" style={{ padding: '10px' }} />
                  <div className="help-block with-errors"></div>
                </div>
              </div>
              <div className="col-lg-6 my-3">
                <div className="form-group">
                  <input type="text" value={Number} onChange={changeHandler} className="form-control" title='' name="Number" placeholder="Phone Number" id="phone_number" required="Number is Required" data-error="Please enter your number" fdprocessedid="cf7ig" style={{ padding: '10px' }} />
                  <div className="help-block with-errors"></div>
                </div>
              </div>
              <div className="col-lg-6 my-3">
                <div className="form-group">
                  <input type="text" value={Subject} onChange={changeHandler} className="form-control" title='' name="Subject" placeholder="Subject" id="msg_subject" required="Subject is Required" data-error="Please enter your subject" fdprocessedid="4mor3e" style={{ padding: '10px' }} />
                  <div className="help-block with-errors"></div>
                </div>
              </div>
              <div className="col-lg-12 my-3">
                <div className="form-group">
                  <textarea name="Message" value={Message} onChange={changeHandler} className="form-control" id="message" cols="30" rows="6" placeholder="Type Your Message Here" required="Message is required" data-error="Write your message"></textarea>
                  <div className="help-block with-errors"></div>
                </div>
              </div>
              <div className="col-lg-12 col-md-12">
                <button type="submit" className="btn " title='' name="contact" style={{ backgroundColor: "rgb(17, 112, 150)", color: "white" }} >Send Message</button>
                <div id="msgSubmit" className="h3 text-center hidden"></div>
                <div className="clearfix"></div>
              </div>

            </div>

          </div>


        </form>

        {/* MAP....... */}

      </div>
      <div className="  map mt-3 mb-5 ">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3829.34704975401!2d80.44273977376275!3d16.30520213308025!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a4a75fdfedd0881%3A0xed1f7f711fd05ce0!2sSHRI%20Hospital%20%26%20Research%20Institute!5e0!3m2!1sen!2sin!4v1691405548150!5m2!1sen!2sin" width="600" height="450" style={{ border: '0' }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title='Shri Hospital'></iframe>
      </div>

    </>

  )
}
export default Contact
