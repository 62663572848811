import React from 'react'

const Dr_Bharani_Rachagulla = () => {
  return (
    <>
      <div style={{ height: '120px' }}></div>
      <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(17, 112, 150)", }}>
        <div className="col text-center strip1" style={{ color: 'white' }}>
          <div className="row mt-4" >
            <h1 style={{ textTransform: 'uppercase' }}>Dr Bharani Rachagulla</h1>
          </div>
          <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
            <li ><a href="/home">Home</a></li>

          </ul>
        </div>
      </div>
      <div className='container'>
        <div className='row'>
          <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
            <div className="row">
              <div className="col-lg-7 mb-2">
                <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                  <span style={{ color: "rgb(17, 112, 150)" }}>Dr Bharani Rachagulla</span>
                </h1>
                <p style={{textAlign:'justify'}}>Dr Bharani is a consultant, clinical and interventional pulmonologist with over 10 years of experience. She has completed MBBS at KIMS Amalapuram, East Godavari. Dr Bharani has completed DTCD at Guntur Medical College. She has done DNB (Pulmonary Medicine) and Fellow in Interventional Pulmonology from Yashoda Hospital, Somajiguda,Hyderabad.</p>
                <p style={{textAlign:'justify'}}>She has undergone extensive training and acquired the skill to perform :</p>
                {/* <p>Dr. Bharani is a highly experienced consultant specializing in clinical and interventional pulmonology, amassing over 10 years of expertise in the field. She earned her Bachelor of Medicine, Bachelor of Surgery (MBBS) degree from KIMS Amalapuram, East Godavari. Furthering her education, Dr. Bharani completed a Diploma in Tuberculosis and Chest Diseases (DTCD) at Guntur Medical College. Her pursuit of excellence led her to achieve a Doctorate of National Board (DNB) in Pulmonary Medicine and a Fellowship in Interventional Pulmonology from Yashoda Hospital, Somajiguda, Hyderabad. This comprehensive academic and professional background underscores her proficiency in the diagnosis, management, and intervention of pulmonary conditions, showcasing her dedication to providing specialized care in the realm of respiratory health.</p> */}
                <ul style={{textAlign:'justify'}}>
                  {/* <li>Dr Bharani is a consultant, clinical and interventional pulmonologist with over 10 years of experience.</li>
                  <li>Dr Bharani has completed MBBS at KIMS Amalapuram, East Godavari.</li>
                  <li>Dr Bharani has completed DTCD at Guntur Medical College</li>
                  <li>She has done DNB (Pulmonary Medicine) and Fellow in Interventional Pulmonology from Yashoda Hospital, Somajiguda,Hyderabad</li> */}
                  {/* <li>She has undergone extensive training and acquired the skill to perform:-</li> */}
                  <li>Bronchoscopy which includes flexible bronchoscopy, transbronchial lung biopsy, Endocbronchial biospsy</li>
                  <li>Endobronchial ultrasound (EBUS) both linear and Radial</li>
                  <li>Thoracoscopy</li>
                  <li>Foreign body extraction</li>
                  <li>Indwelling plural catheter</li>
                 
                </ul>

                {/* <p><span style={{ fontSize: '25px' }}>&#8594;</span> </p>
                <p><span style={{ fontSize: '25px' }}>&#8594;</span></p>
                <p><span style={{ fontSize: '25px' }}>&#8594;</span> </p>
                <p><span style={{ fontSize: '25px' }}>&#8594;</span></p>
                <p><span style={{ fontSize: '25px' }}>&#8594;</span> </p> */}

              </div>
              <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                <img className="mt-5" style={{ width: '80%', height: '300px', borderRadius: '15px' }} id="ServicesImg" src='1689136434540.jpg' alt=''></img>
              </div>
            </div>
            {/* <p><span style={{ fontSize: '25px' }}>&#8594;</span> </p>
            <p><span style={{ fontSize: '25px' }}>&#8594;</span> </p>
            <p><span style={{ fontSize: '25px' }}>&#8594;</span> </p>
            <p><span style={{ fontSize: '25px' }}>&#8594;</span> </p>
            <p><span style={{ fontSize: '25px' }}>&#8594;</span> </p> */}
            <p style={{textAlign:'justify'}}>She has training in the management of sleep disorder such as polysomnography , Positive airway pressure therapy in OSA, COPD, obesity hypoventilation syndrome, neuromuscular disorders and all other sleep disordered breathing.</p>
            <p style={{textAlign:'justify'}}>She has good experience in ICU Management, trained in the use of Non-invasive ventilator strategies, High flow nasal cannula.</p>
            <p style={{textAlign:'justify'}}>Her skills lie in the management of COPD, Asthma, bronchiectasis, tuberculosis, ILD, ABPA, Pulmonary Vascular disorders, Occupational Lung disease, Pleural & medistinal disorder of lung and pulmonary rehabilation.</p>
          </div>

        </div>
      </div>
    </>
  )
}

export default Dr_Bharani_Rachagulla