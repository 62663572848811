import React from 'react'
import { Button, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'


const Blogs = () => {
  return (
    <>
     <div style={{ height: '120px' }}></div>
      <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(17, 112, 150)", }}>
        <div className="col text-center strip1" style={{ color: 'white' }}>
          <div className="row mt-4" >
            <h1 style={{ textTransform: 'uppercase' }}>BLOGS</h1>
          </div>
          <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
            <li><Link to='/Home'>Home</Link></li>

          </ul>
        </div>
      </div>

      <div className='container mb-4' id="events" data-aos="zoom-in" data-aos-duration="2000">
                <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginTop: '30px' }}>
                    <span style={{ color: "rgb(17, 112, 150)" }}> Blogs</span>
                </h1>
            </div>
            <div className='container text-center' data-aos="zoom-in-right" data-aos-duration="2000" style={{ alignItems: 'center', paddingLeft: '60px', justifyContent: 'center' }}>
                <div className='row' style={{ justifyContent: 'center' }}>
                    <div className='col mb-2'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="AsthmaBlog.png" alt='' />
                            <Card.Body>
                                <Card.Title>Know About Asthma</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/Know_About_Asthma'>Know More</Link>
                            </Card.Body>
                        </Card>

                    </div>
                    <div className='col mb-2'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="TIPSTOASTHMA.png" alt='' />
                            <Card.Body>
                                <Card.Title>Tips To Keep Your Asthma Under Control</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/Tips_keep_asthma_under_control'>Know More</Link>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className='col mb-2'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="SmokingBlog-transformed.png" alt='' style={{height:'200px'}}/>
                            <Card.Body>
                                <Card.Title>Quit Smoking Today Before It’s Too Late</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/Quit_Smoking_Today_Before_Its_Too_Late'>Know More</Link>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>


            <div className='container text-center' data-aos="zoom-in-right" data-aos-duration="2000" style={{ alignItems: 'center', paddingLeft: '60px', justifyContent: 'center' }}>
                <div className='row' style={{ justifyContent: 'center' }}>
                    <div className='col mb-2'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="PneumoniabLOG-transformed.png" alt='' />
                            <Card.Body>
                                <Card.Title>Pneumonia – a common illness in winters</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/Pneumonia_a_common_illness_in_winters'>Know More</Link>
                            </Card.Body>
                        </Card>

                    </div>
                    <div className='col mb-2'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="HealthyLungs.jpg" alt='' />
                            <Card.Body>
                                <Card.Title>Role of Diet and Nutrients in Maintaining Healthy Lungs</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/Role_diet_nutrients_maintaining_healthy_lungs'>Know More</Link>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className='col mb-2'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="typesofAsthma.jpg" alt='' />
                            <Card.Body>
                                <Card.Title>Types Of Asthma</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/Types_Of_Asthma'>Know More</Link>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>


            <div className='container text-center' data-aos="zoom-in-right" data-aos-duration="2000" style={{ alignItems: 'center', paddingLeft: '60px', justifyContent: 'center' }}>
                <div className='row' style={{ justifyContent: 'center' }}>
                    <div className='col mb-2'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="myths.png" alt='' />
                            <Card.Body>
                                <Card.Title>Myths About Using Inhalers</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/Myths_About_Using_Inhalers'>Know More</Link>
                            </Card.Body>
                        </Card>

                    </div>
                    <div className='col mb-2'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="AsthmaCauses.jpg" alt='' />
                            <Card.Body>
                                <Card.Title>Asthma-Causes, Symptoms, and Treatment in Adults and Children</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/Asthma_in_adults_and_children'>Know More</Link>
                            </Card.Body>
                        </Card>
                    </div>
                     <div className='col mb-2'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="COPD.jpg" alt='' />
                            <Card.Body>
                                <Card.Title>COPD (Chronic Obstructive Pulmonary Disease)</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/Chronic_obstructive_pulmonary_disease'>Know More</Link>
                            </Card.Body>
                        </Card>
                    </div> 
                </div>
            </div>
            <div className='container text-center' data-aos="zoom-in-right" data-aos-duration="2000" style={{ alignItems: 'center', paddingLeft: '60px', justifyContent: 'center' }}>
                <div className='row' style={{ justifyContent: 'center' }}>
                    <div className='col mb-2'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="Why_Asthama_Worse_in_Winter.jpg" alt='' />
                            <Card.Body>
                                <Card.Title>Why Asthma Worse In Winter</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/Why_asthma_worse_in_winter'>Know More</Link>
                            </Card.Body>
                        </Card>

                    </div>
                    {/* <div className='col mb-2'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="AsthmaCauses.jpg" alt='' />
                            <Card.Body>
                                <Card.Title>Asthma-Causes, Symptoms, and Treatment in Adults and Children</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/Asthma_in_adults_and_children'>Know More</Link>
                            </Card.Body>
                        </Card>
                    </div>
                     <div className='col mb-2'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="COPD.jpg" alt='' />
                            <Card.Body>
                                <Card.Title>COPD (Chronic Obstructive Pulmonary Disease)</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/Chronic_obstructive_pulmonary_disease'>Know More</Link>
                            </Card.Body>
                        </Card>
                    </div>  */}
                </div>
            </div>
    </>
  )
}

export default Blogs