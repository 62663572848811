import React from 'react'

const Pneumonia_a_common_illness_in_winters = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(17, 112, 150)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>Pneumonia – a common illness in winters</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/home">Home</a></li>
                    </ul>
                </div>
            </div>
            <div className='container'>
                <div className='row'>
                    <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                        <div className="row">
                            <div className="col-lg-7 mb-2">
                                <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                                    <span style={{ color: "rgb(17, 112, 150)" }}>Pneumonia – a common illness in winters</span>
                                </h1>
                                <p style={{ alignItems: 'justify' }}>
                                    With winter, people start suffering from cold, cough, and fever. While most cases are that of simple flu, some may be of Community-Acquired Pneumonia, commonly known as CAP. People who develop the flu can sometimes develop Pneumonia as a secondary infection. Cold, dry air and the tendency of people to gather indoors nearby during cold periods enhance the likelihood of both airborne and contact transmission of flu and Pneumonia causing microorganisms.
                                </p>
                                <p style={{ alignItems: 'justify' }}>
                                    Pneumonia is an infection that involves the air sacs in one or both lungs. It is a prominent cause of suffering and death with great clinical impact across the globe. India accounts for 23% of the global pneumonia burden, with a case fatality rate ranging from 14 to 30%.
                                </p>

                            </div>
                            <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                                <img className="mt-5" style={{ height: '300px', width: '80%', borderRadius: '15px' }} id="ServicesImg" src='PneumoniabLOG-transformed.png' alt=''></img>
                            </div>
                        </div>

                        <p style={{ alignItems: 'justify' }}>
                            It can range in severity from mild to serious and even life-threatening illness. Various organisms, including bacteria, viruses, and fungi, can cause Pneumonia. The most common bacterial pathogen is Streptococcus Pneumoniae, followed by Hemophilus Influenzae, Atypical organisms, and Gram Negative Bacteria like Klebsiella and Pseudomonas.
                        </p>
                        <p style={{ alignItems: 'justify' }}>
                            The risk factors for acquiring Pneumonia and developing its complications are extremes of age ( 2 years and 65 years), smoking, alcohol intake, and presence of comorbidities like Diabetes, Chronic Lung diseases like Bronchial Asthma, Chronic Obstructive Pulmonary Disease (COPD), Interstitial Lung Disease, Chronic Heart Disease, Chronic Kidney and Liver Diseases, leak of brain fluid, cochlear implant, and any immunosuppressive state like AIDS, cancers and prolonged courses of steroids and immunosuppressive therapy.
                        </p>
                        <p style={{ alignItems: 'justify' }}>
                            The symptoms usually range from cough, phlegm production, rust-coloured sputum, fever, and chest pain to breathlessness, respiratory failure, and blood pressure drop.
                        </p>
                        <p style={{ alignItems: 'justify' }}>
                            A diagnosis of Pneumonia is reached based on typical symptomatology, physical examination, some blood tests, sputum examination, and a chest X-Ray.
                        </p>
                        <p style={{ alignItems: 'justify' }}>
                            Early treatment initiation with appropriate antibiotics is essential for a successful recovery. People who develop any of the above symptoms must see their physicians immediately. The usual duration of antibiotics is 5 to 7 days.
                        </p>

                        <p style={{ alignItems: 'justify' }}>
                            Complications that can develop despite appropriate therapy include the spread of infection in the blood (Bacteremia), sepsis, drop in blood pressure (Shock), Multi-organ involvement, development of pus inside the chest (Empyema), formation of lung abscess, and death.
                        </p>
                        <p style={{ alignItems: 'justify' }}>Pneumonia can be prevented by following good hygiene practices, not smoking or quitting smoking, and taking appropriate flu and pneumococcal vaccinations.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Pneumonia_a_common_illness_in_winters