import React from 'react'

const ILD = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(17, 112, 150)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>EXPERT ILD AND PULMONARY HYPERTENSION CONSULTATION</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/home">Home</a></li>

                    </ul>
                </div>
            </div>

            <div className='container'>
                <div className='row'>
                    <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                        <div className="row">
                            <div className="col-lg-7 mb-2">
                                <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                                    <span style={{ color: "rgb(17, 112, 150)" }}>EXPERT ILD AND PULMONARY HYPERTENSION CONSULTATION</span>
                                </h1>
                                <p>
                                    Pulmonary hypertension is known as PH occurs when the blood pressure in the lungs elevates abnormally. ILD (interstitial lung diseases) is a group of diseases that cause scarring of the lungs.PH can be caused due to the thickening of a pulmonary artery wall, clots in the lung’s blood vessel, lung diseases (ILD), heart failure, etc.
                                </p>
                                <p>Pulmonary hypertension is different from normal hypertension and it can occur to patients who may have or not have ordinary hypertension.</p>

                                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                                    <span style={{ color: "rgb(52, 186, 240)" }}>Symptoms of Pulmonary hypertension and ILD are similar which are mentioned below</span>
                                </h4 >
                                <p>Difficulty breathing when lying flat   
                                </p>
                                <p>Chest Pain</p>
                                <p>Swelling of the ankles</p>
                                <p>Fainting</p>
                                <p>Expert ILD And Pulmonary Hypertension Consultation</p>
                               

                            </div>
                            <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                                <img className="mt-5" style={{ width: '100%', height: '300px', borderRadius: '15px' }} id="ServicesImg" src='WhatsApp Image 2023-08-05 at 11.54.37 AM (2).jpeg' alt=''></img>
                            </div>
                        </div>
                        <p>Diagnosis tests,</p>
                                <p>For Pulmonary Hypertension</p>
                                <p>Blood Test</p>
                                <p>Echocardiogram</p>
                                <p>Right Heart Catheterization</p>
                                <p>Computed Tomography (CT) Angiogram</p>
                                <p>Ventilation-Perfusion Scan (V/Q scan).</p>
                                <p>For ILD</p>
                                <p>Chest X-Ray</p>
                                <p>CT Scan</p>
                                <p>Lung Biopsy</p>
                                <p>Bronchoscopy</p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}>Consultation</span>
                        </h4 >
                        <p style={{ textAlign: 'justify' }}>Consultation of ILD from interventional pulmonology doctors in Guntur at lung hospitals depends on the type of diagnosing and severity. So, its consultation generally includes, relieving symptoms, improving quality of life, and slowing disease progression.</p>
                        <p style={{ textAlign: 'justify' }}>Whereas, if ILD is developed in pulmonary hypertension then doctors at chest medicine hospitals in Guntur consult treatment from supportive therapy or PH-specific therapy</p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}>Support therapy</span>
                        </h4 >
                        <p>includes supplemental oxygen to increase oxygen supply in the blood, diuretics to prevent swelling, Anticoagulants for thinning blood, and pulmonary rehabilitation.</p>
                       
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}>Specific therapy</span>
                        </h4 >
                        <p>includes Prostanoid, Endothelin Receptor Antagonists, Phosphodiesterase Inhibitors.</p>

                    </div>

                </div>
            </div>
        </>
    )
}

export default ILD