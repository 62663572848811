
import { useState ,useRef } from 'react';
import {Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import emailjs from 'emailjs-com'


const Header = () => {
  
  // bookappointment script 
  const form = useRef()
  const [data, updateData] = useState({
      Name: "",
      Email: '',
      Number: '',
      Subject: '',
      Message: ''
    })

  const { Name, Email, Number, Subject, Message } = data;
  
  const changeHandler = e => {
    updateData({ ...data, [e.target.name]: [e.target.value] })

  }
  const submitHandler = e => {
    e.preventDefault();

    emailjs.sendForm('service_yb4ra2b', 'template_oeazm4o', e.target, '7Y-oXT2ydz4Kin8Jb')
      .then((result) => {
        alert("Successfully Booked Your Appointment ")
        updateData({
          Name: "",
          Email: "",
          Number: "",
          Subject: '',
          Message: ''

        })

      }, (error) => {
        console.log(error.text);
      });

  }

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Redirect links...
  const [Copd,setCopd]=useState(false);
  const [AB,setAb]=useState(false);
  const [Cb,setCb]=useState(false);
  const [Asthma,setAsthma]=useState(false);
  const [Pneumonia,setPneumonia]=useState(false);
  const [Tuberculosis,setTuberculosis]=useState(false);
  const [Pulmonary,setpe]=useState(false);
  const [Interventional,setip]=useState(false);
  const [Infection,setIb]=useState(false);
  const [ILD,setILD]=useState(false);
  const [Allergy,setAm]=useState(false);
  const [Critical,setCc]=useState(false);
  const [home,sethome]=useState(false);
  const [about,setabout]=useState(false);
  const [contact,setcontact]=useState(false);
  const [Bharani,setBharani]=useState(false);
  const [WhyChooseUs,setWhyChoose]=useState(false);
  const [Blogs,setBlogs] = useState(false);
  const [Gallery,setGallery]= useState(false);
  const [Targeted,setTargeted] = useState(false);

  if(Targeted){
    return<div>
        <Header/>
    <return to='/Targeted_Asthma_Therapy'/>
    </div>   
  }
  if(Blogs){
    return<div>
        <Header/>
    <return to='/Blogs'/>
    </div>   
  }
  if(Gallery){
    return<div>
        <Header/>
    <return to='/Gallery'/>
    </div>   
  }
  if(about){
    return<div>
        <Header/>
    <return to='/about'/>
    </div>   
  }
  if(WhyChooseUs){
    return<div>
        <Header/>
    <return to='/Why_ChooseUs'/>
    </div>   
  }
  if(contact){
    return<div>
        <Header/>
    <return to='/contact'/>
    </div>   
  }

  if(Bharani){
    return<div>
        <Header/>
    <return to='/Dr_Bharani_Rachagulla'/>
    </div>   
  }
  if(Copd){
    return<div>
        <Header/>
    <return to='/Best_Fever_hospital_inGuntur'/>
    </div>  
  }
  if(AB){
    return<div>
        <Header/>
    <return to='/Any_Time_Monitoring'/>
    </div>   
  }
  if(home){
    return<div>
        <Header/> 
    <return to='/Home'/>
    </div>   
  }
  if(Cb){
    return<div>
        <Header/>
    <return to='/Chronic_Bronchitis'/>
    </div>   
  }
  if(Asthma){
    return<div>
        <Header/>
    <return to='/Best_Asthma_Hospital_InGuntur'/>
    </div>  
  }
  if(Pneumonia){
    return<div>
        <Header/>
    <return to='/Pneumonia'/>
    </div>  
  }
  if(Tuberculosis){
    return<div>
        <Header/>
    <return to='/Tuberculosis'/>
    </div>  
  }
  if(Pulmonary){
    return<div>
        <Header/>
    <return to='/Pulmonary_Emphysema'/>
    </div>  
  }

  if(Interventional){
    return<div>
        <Header/>
    <return to='/Interventional_Pulmonology'/>
    </div>  
  }

  if(Infection){
    return<div>
        <Header/>
    <return to='/Infection_diseases'/>
    </div>  
  }

  if(ILD){
    return<div>
        <Header/>
    <return to='/ILD'/>
    </div>  
  }
  if(Allergy){
    return<div>
        <Header/>
    <return to='/Allergy_Managment'/>
    </div>  
  }
  if(Critical){
    return<div>
        <Header/>
    <return to='/Critical_Care'/>
    </div>  
  }

   
  return (
    
    <>
      <nav>
        <div className='iconss' sticky="top">
          <div className='row'>
            <div className='col col-sm-12 text-center'>
              {/* <a href="#" ><button className="btn" onClick={handleShow} style={{ color: 'white', border: 'none', textAlign: 'center', textDecoration: 'none', display: ' inline-block', fontSize: '16px', backgroundColor: 'rgb(245, 41, 41)' }}>Book Appointment</button></a> */}
              {/* <div  className="dropdown" >
              <button className="dropbtn" style={{marginRight:'30px'}}>Appointment</button>
              <div className="dropdown-content " >
                <a  href="tel:9000 10">link1</a>
                <a  href="tel:70326 " style={{marginLeft:'-100px'}}>link2</a>
                
              </div>
              </div> */}
              {/* <a href=''><i class="bi bi-phone-flip">Emergency Call</i></a> */}
              <div  className="dropdown" >
              <a href='tel:8978186668' style={{textDecoration:'none'}}><button className="dropbtn mt-1" style={{backgroundColor:"white",color:"red",padding:'1%',marginLeft:'-2%',marginBottom:'1%'}}>Emergency</button>   8978186668</a>
              {/* <a href='tel:8978186668'></a> */}
              {/* <div className="dropdown-content " >
              <a  href="tel:9000 1">link1</a>
                <a  href="tel:70326" style={{marginLeft:'-100px'}}>link2</a>
                
              </div> */}
              </div>
             
            </div>
            {/* <div className='col-md-6 text-center mt-1' style={{ justifyContent: 'center' }}>
              <Link to='https://www.facebook.com/DrBharanirachagulla' title='Facebook'><i className="bi bi-facebook"></i></Link>
              <Link to='/' title='Twitter'><i className="bi bi-twitter"></i></Link>
              <Link to='https://www.youtube.com/channel/UCjHux3HMMb_nIBgy_eBuS4A' title='Youtube'><i className="bi bi-youtube"></i></Link>
              <Link to='https://www.instagram.com/drbharanirachagulla?fbclid=IwAR0IUOd-pTwoIhxgFueYugKa9kmF6fvDIg9jGa8gsvmPEWsvuZO2UdS98kM' title='Instagram' ><i className="bi bi-instagram"></i></Link>
            </div> */}
          </div>
        </div>
        <div className="wrapper" sticky="top" style={{ backgroundColor: 'white',boxShadow:'rgba(50, 50, 93, 0.3) 0px 30px 30px 10px' }} >
          <div className="logo"><Link to="#"><img src='DR Bharani LogoFooter.png' alt='' style={{ width: '220px', height: '220px' ,marginTop:'-13px'}}></img></Link></div>
          
          <input type="radio" name="slider" placeholder='null' title='menu' id="menu-btn" />
          <input type="radio" name="slider" placeholder='null' title='close'  id="close-btn" />
          <ul className="nav-links">
            <label htmlFor="close-btn" style={{ color: 'black', width: '20px' }} className="btn close-btn"><i className="bi bi-x-circle"></i></label>
            <li><Link to="/Home" onClick={()=>sethome(true)} style={{ color: 'black' }}>Home</Link></li>
            <li>
              <Link to="#" className="desktop-item" onClick={()=>setabout(true)} style={{ color: 'black' }}>About</Link>
              <input type="checkbox" id="showDrop" />
              <label htmlFor="showDrop" className="mobile-item" style={{ color: 'black' }}>About</label>
              <ul className="drop-menu">
                <li><Link to="Dr_Bharani_Rachagulla" onClick={()=>setBharani(true)}>Dr Bharani Rachagulla</Link></li>
                <li><Link to="Why_ChooseUs" onClick={()=>setWhyChoose(true)}>Why Choose Us</Link></li>

              </ul>
            </li>
            <li><Link to="Gallery" style={{ color: 'black' }} onClick={()=>setGallery(true)}>Gallery</Link></li>
            <li><Link to="Blogs" style={{ color: 'black' }} onClick={()=>setBlogs(true)}>Blogs</Link></li>
            {/* <li>
              <Link href="#" className="desktop-items" style={{ color: 'black' }}>Blog</Link>
              <input type="checkbox" id="showDrops" />
              <label htmlFor="showDrops" className="mobile-item" style={{ color: 'black' }}>Blog</label>
              <ul className="drop-menus">
                <li><Link to="#">Drop menu 1</Link></li>
                <li><Link to="#">Drop menu 2</Link></li>
                <li><Link to="#">Drop menu 3</Link></li>
                <li><Link to="#">Drop menu 4</Link></li>
              </ul>
            </li> */}
            <li>
              <Link to="#" className="desktop-item" style={{ color: 'black' }}>Specialities</Link>
              <input type="checkbox" id="showMega" />
              <label htmlFor="showMega" className="mobile-item" style={{ color: 'black' }} >Specialities</label>
              <div className="mega-box">
                <div className="content">

                  <div className="row">
                    {/* <header>Design Services</header> */}
                    <ul className="mega-links">
                    <li><Link to="Best_Asthma_Hospital_InGuntur" onClick={()=>setAsthma(true)}>Asthma</Link></li>
                      <li><Link to="Chronic_Obstructive_Pulmonary_Diseases" onClick={()=>setCopd(true)}>COPD</Link></li>
                      <li><Link to="Tuberculosis" onClick={()=>setTuberculosis(true)}>Tuberculosis</Link></li>
                      <li><Link to="Pneumonia" onClick={()=>setPneumonia(true)}>Pneumonia</Link></li>
                      <li><Link to="Pulmonary_Emphysema" onClick={()=>setpe(true)}>Pulmonary Emphysema</Link></li>




                    </ul>
                  </div>
                  <div className="row">
                    {/* <header>Email Services</header> */}
                    <ul className="mega-links">
                      {/* <li><a href="SEPSIS_CRITICAL_CARE">SEPSIS & CRITICAL CARE</a></li> */}
                      <li><Link to="Acute_Bronchitis" onClick={()=>setAb(true)}>Acute Bronchitis</Link></li>
                      <li><Link to="/Chronic_Bronchitis" onClick={()=>setCb(true)}>Chronic Bronchitis</Link></li>
                      <li><Link to="Targeted_Asthma_Therapy" onClick={()=>setTargeted(true)} style={{fontSize:'16px'}}>Targeted Asthma Therapy</Link></li>
                      <li><Link to="ALLERGY_MANAGMENT" onClick={()=>setAm(true)}>Allergy Managment</Link></li>
                      <li><Link to="ILD" onClick={()=>setILD(true)} >ILD</Link></li>

                    </ul>
                  </div>
                  <div className="row">
                    {/* <header>Security services</header> */}
                    <ul className="mega-links">
                    <li><Link to="Swasa_Clinic" onClick={()=>setPneumonia(true)}>Swasa Clinic</Link></li>
                    <li><Link to="Infection_diseases" onClick={()=>setIb(true)}>Infection Diseases</Link></li>
                      <li><Link to="Critical_Care" onClick={()=>setCc(true)}> Critical Care</Link></li>
                      <li><Link to="Interventional_Pulmonology" onClick={()=>setip(true)}>Interventional Pulmonology</Link></li>

                    </ul>
                  </div>
                </div>
              </div>
            </li>
            <li><Link to="Contact" style={{ color: 'black' }} onClick={()=>setcontact(true)}>Contact</Link></li>  
             <Link to="#" ><button className="btn" onClick={handleShow} style={{ color: 'white', border: 'none', padding: '15px 32px', textAlign: 'center', textDecoration: 'none', display: ' inline-block', fontSize: '16px', margin: '4px 2px', backgroundColor: 'rgb(17, 112, 150)' }}>Book Appointment</button></Link>
          </ul>
          <label htmlFor="menu-btn" className="btn menu-btn" style={{ color: 'black' }}><i className="bi bi-list" style={{ blockSize: '100px' }} ></i></label>
        </div>
      </nav>

      <Modal style={{ marginTop: '50px' }} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className='text-center' style={{ color: 'rgb(17, 112, 150)',textAlign:'center' }}>Book An Appointment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form ref={form} onSubmit={submitHandler}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <input type="text" value={Name} onChange={changeHandler} className="form-control" placeholder="Your Name" name="Name" required="Username is Required..." message="Username must be atmost 30 characters long..." style={{ padding: '10px' }} />
              <div className="help-block with-errors"></div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <input type="email" value={Email} onChange={changeHandler} className="form-control" placeholder="Your Email Address" name="Email" id="email" required="Email is required" data-error="Please enter your email" fdprocessedid="p2hd6j" style={{ padding: '10px' }} />
              <div className="help-block with-errors"></div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <input type="text" value={Number} onChange={changeHandler} className="form-control" name="Number" placeholder="Phone Number" id="phone_number" required="Number is Required" data-error="Please enter your number" fdprocessedid="cf7ig" style={{ padding: '10px' }} />
              <div className="help-block with-errors"></div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <input type="text" value={Subject} onChange={changeHandler} className="form-control" name="Subject" placeholder="Subject" id="msg_subject" required="Subject is Required" data-error="Please enter your subject" fdprocessedid="4mor3e" style={{ padding: '10px' }} />
              <div className="help-block with-errors"></div>
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <textarea name="Message" value={Message} onChange={changeHandler} className="form-control" id="message" cols="30" rows="3" placeholder="Type Your Message Here" required="Message is required" data-error="Write your message"></textarea>
              <div className="help-block with-errors"></div>
            </Form.Group>
            <button type="submit" className="btn " name="contact" style={{ backgroundColor: "rgb(17, 112, 150)", color: "whitesmoke" }} >Send Message</button>
            <div id="msgSubmit" className="h3 text-center hidden"></div>
            <div className="clearfix"></div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Header