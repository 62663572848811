import React from 'react'

const Types_Of_Asthma = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(17, 112, 150)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>Types Of Asthma</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/home">Home</a></li>
                    </ul>
                </div>
            </div>
            <div className='container'>
                <div className='row'>
                    <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                        <div className="row">
                            <div className="col-lg-7 mb-2">
                                <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                                    <span style={{ color: "rgb(17, 112, 150)" }}>Types Of Asthma</span>
                                </h1>
                                <p style={{ alignItems: 'justify' }}>
                                    Asthma is a lung disease that makes your airways constricted, thus making it difficult for you to breathe. This happens because of the swelling and inflammation in the airways, mucous plugging and spasm of the smooth muscles of the airways.
                                </p>
                                <p style={{ alignItems: 'justify' }}>
                                    The common symptoms of Asthma are Cough with sticky phlegm, chest tightness, shortness of breath and wheezing (whistling kind of sound coming from the chest). Asthma can be classified in various ways. Knowing about the kind of Asthma will help you deal with it better. Keep reading to find out more about Asthma.
                                </p>
                                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                                    <span style={{ color: "rgb(52, 186, 240)" }}>Following are the various types of Asthma.</span>
                                </h4 >
                                <p style={{ alignItems: 'justify' }}>
                                    1.	Allergic asthma
                                </p>
                                <p style={{ alignItems: 'justify' }}>
                                    2.	‘Seasonal’ Asthma
                                </p>
                                <p style={{ alignItems: 'justify' }}>
                                    3.	Occupational Asthma
                                </p>
                                <p style={{ alignItems: 'justify' }}>
                                    4.	Non-allergic asthma
                                </p>
                                <p style={{ alignItems: 'justify' }}>
                                    5.	‘Exercise induced’ Asthma
                                </p>
                                <p style={{ alignItems: 'justify' }}>
                                    6.	Difficult Asthma
                                </p>
                                <p style={{ alignItems: 'justify' }}>
                                    7.	Severe Asthma
                                </p>

                            </div>
                            <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                                <img className="mt-5" style={{ height: '300px', width: '80%', borderRadius: '15px' }} id="ServicesImg" src='typesofAsthma.jpg' alt=''></img>
                            </div>
                        </div>
                        <h2 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(17, 112, 150)" }}>Let’s Read About Each One In Detail.</span>
                        </h2>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}>1.	Allergic Asthma</span>
                        </h4 >
                        <p style={{ alignItems: 'justify' }}>
                            The common allergens that can trigger symptoms of Asthma in an individual are pollen; animal dander such as feathers, hair, faeces, feathers, animal saliva, cockroach faeces, dust mites, faeces, and mould spores. Certain food items like milk, eggs, tree nuts, peanuts, seafood, wheat and soybeans are also known to cause allergies.
                        </p>

                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}>2.	Seasonal Asthma</span>
                        </h4 >
                        <p style={{ alignItems: 'justify' }}>
                        Some people may develop symptoms at the change of season due to variations in temperatures and humidity. This is called seasonal Asthma and could also be due to the presence of certain allergens like specific pollens in specific seasons that trigger Asthma.
                        </p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}>3.	Occupational Asthma</span>
                        </h4 >
                        <p style={{ alignItems: 'justify' }}>
                        As the name suggests, occupational Asthma is caused due to breathing allergens in the workplace. For example, you might be allergic to wheat flour, and if you work in a bakery, that may result in occupational Asthma. Similarly, you might be allergic to latex powder used in gloves, and if you work in the healthcare industry, that can result in Asthma.
                        </p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}>4.	Non-Allergic Asthma</span>
                        </h4 >
                        <p style={{ alignItems: 'justify' }}>
                        It is also known as non-atopic Asthma and is not caused due to an allergen. The exact cause of this type of Asthma is not known, but it generally develops later in life, and symptoms can be severe.
                        </p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}>5. Exercise-induced</span>
                        </h4 >
                        <p style={{ alignItems: 'justify' }}>
                        If you get asthma-like symptoms only during strenuous exercising, you might be suffering from exercise-induced Asthma. Consult your doctor if you are not diagnosed with Asthma yet, but you get symptoms like chest tightness, coughing, and breathlessness after exercising. Exercise-induced Asthma is better called ‘exercise-induced bronchoconstriction’ (EIB) because exercise causes bronchoconstriction but is not a direct cause of Asthma. Exercise is one of the many factors that are responsible for Asthma. The symptoms usually start 15 minutes after the exercise but could occur even after 4-12 hours after cessation of exercise.
                        </p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}>6.	 Asthma.</span>
                        </h4 >
                        <p style={{ alignItems: 'justify' }}>
                        If you get asthma-like symptoms only during strenuous exercising, you might be suffering from exercise-induced Asthma. Consult your doctor if you are not diagnosed with Asthma yet, but you get symptoms like chest tightness, coughing, and breathlessness after exercising. Exercise-induced Asthma is better called ‘exercise-induced bronchoconstriction’ (EIB) because exercise causes bronchoconstriction but is not a direct cause of Asthma. Exercise is one of the many factors that are responsible for Asthma. The symptoms usually start 15 minutes after the exercise but could occur even after 4-12 hours after cessation of exercise.
                        </p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}>7.	Difficult Asthma</span>
                        </h4 >
                        <p style={{ alignItems: 'justify' }}>
                        Difficult Asthma is a condition in which you find it difficult to manage symptoms because you have other health issues or because you miss a dose of preventive medications. The following symptoms indicate that you might be suffering from difficult Asthma.
                        </p>
                        <ul>
                            <li>If your symptoms do not improve despite taking high doses of medications.</li>
                            <li>Frequent attacks of Asthma requiring hospitalisation</li>
                        </ul>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}>8.	Severe Asthma</span>
                        </h4 >
                        <p style={{ alignItems: 'justify' }}>
                        A patient is said to have Severe Asthma if the diagnosis of Asthma has been confirmed using various investigative modalities, existing comorbidities have been controlled/treated, adherence to therapy & correct inhaler technique has been confirmed, and triggers have been avoided, and yet the symptoms are not settling.
                        </p>
                        <p style={{ alignItems: 'justify' }}>
                        If you had more than two attacks of Asthma and despite taking inhalers and preventive medications, you don’t get relief, you could be suffering from severe Asthma.
                        </p>

                    </div>
                </div>
            </div>


            {/* <div className='container'>
                <div className='row'>
                    <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                        <div className="row">
                            <div className="col-lg-7 mb-2">
                                <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                                    <span style={{ color: "rgb(17, 112, 150)" }}>Types Of Asthma</span>
                                </h1>
                                <p style={{ alignItems: 'justify' }}>
                                </p>
                                <p style={{ alignItems: 'justify' }}>
                                </p>
                                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                                    <span style={{ color: "rgb(52, 186, 240)" }}></span>
                                </h4 >
                                <p style={{ alignItems: 'justify' }}>
                                </p>
                            </div>
                            <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                                <img className="mt-5" style={{ height: '300px', width: '80%', borderRadius: '15px' }} id="ServicesImg" src='WhatsApp Image 2023-08-05 at 11.54.37 AM (4).jpeg' alt=''></img>
                            </div>
                        </div>

                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}></span>
                        </h4 >
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}></span>
                        </h4 >
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}></span>
                        </h4 >
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}></span>
                        </h4 >
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}></span>
                        </h4 >
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}></span>
                        </h4 >
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}></span>
                        </h4 >
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}></span>
                        </h4 >
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}></span>
                        </h4 >
                        <p style={{ alignItems: 'justify' }}>
                        </p>
                        <p style={{ alignItems: 'justify' }}>
                        </p>
                        <p style={{ alignItems: 'justify' }}>
                        </p>
                        <p style={{ alignItems: 'justify' }}>
                        </p>
                        <p style={{ alignItems: 'justify' }}>
                        </p>
                        <p style={{ alignItems: 'justify' }}>
                        </p>
                        <p style={{ alignItems: 'justify' }}>
                        </p>
                        <p style={{ alignItems: 'justify' }}>
                        </p>
                        <p style={{ alignItems: 'justify' }}>
                        </p>
                        <p style={{ alignItems: 'justify' }}>
                        </p>
                        <p style={{ alignItems: 'justify' }}>
                        </p>
                        <p style={{ alignItems: 'justify' }}>
                        </p>

                    </div>
                </div>
            </div> */}
        </>
    )
}

export default Types_Of_Asthma