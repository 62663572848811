import React from 'react'

const Interventional_Pulmonology = () => {
  return (
    <>
    <div style={{ height: '120px' }}></div>
    <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(17, 112, 150)", }}>
        <div className="col text-center strip1" style={{ color: 'white' }}>
            <div className="row mt-4" >
                <h1 style={{ textTransform: 'uppercase' }}>Interventional Pulmonology</h1>
            </div>
            <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                <li ><a href="/home">Home</a></li>
            </ul>
        </div>
    </div>
    <div className='container'>
        <div className='row'>
            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(17, 112, 150)" }}>Interventional Pulmonology</span>
                        </h1>
                        <p style={{alignItems:'justify'}}>
                        Interventional Pulmonology is the new field in pulmonary medicine that utilizes the Bronchoscopy and other tools to diagnose and treat the conditions in the chest and the lungs at pulmonology hospitals in Guntur. The procedures may be done by lung specialist or pulmonologist in Guntur who have underwent additional training.
                        </p>
                       <p style={{alignItems:'justify'}}>
                       Interventional pulmonary procedures provide the advantages to avoid more invasive surgery, for example: prior to interventional pulmonology, biopsy of lymph nodes in chest is needed for chest wall surgery.
                       </p>
                       <p><b>
                       Some of the advanced interventional pulmonology services at chest hospitals in Guntur are:
                       </b></p>
                       <p style={{alignItems:'justify'}}>EBUS (Linear& Radial ) for lung cancer and infections</p>
                       <p style={{alignItems:'justify'}}>Bronchial thermoplasty for severe asthma</p>
                       <p style={{alignItems:'justify'}}>Bronchial thermovapor ablation for advanced COPD</p>

                    </div>
                    <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                        <img className="mt-5" style={{ height: '300px',width:'80%', borderRadius: '15px' }} id="ServicesImg" src='WhatsApp Image 2023-08-05 at 11.54.37 AM (4).jpeg' alt=''></img>
                    </div>
                </div>
                <p style={{alignItems:'justify'}}>Navigation Bronchoscopy for peripheral Pulmonary nodule</p>
                       <p style={{alignItems:'justify'}}>Cryoextraction & ablation</p>
                       <p style={{alignItems:'justify'}}>Electrosurgical procedures</p>
                       <p style={{alignItems:'justify'}}>Rigid Bronchoscopic Tumor removal & airway stenosis repair</p>
                <p style={{alignItems:'justify'}}>Airway stenting (Silicone&SEMS)</p>
                <p style={{alignItems:'justify'}}>Sphigot insertions</p>
                <p style={{alignItems:'justify'}}>Foreign body removal</p>
                <p style={{alignItems:'justify'}}>Thoracoscopy</p>
                <p style={{alignItems:'justify'}}>ICU bronchoscopies</p>
            </div>
        </div>
    </div>
</>
  )
}

export default Interventional_Pulmonology