import React from 'react'

const Targeted_Asthma_Therapy = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(17, 112, 150)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>Targeted Asthma Therapy</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/home">Home</a></li>
                    </ul>
                </div>
            </div>

            <div className='container'>
                <div className='row'>
                    <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                        <div className="row">
                            <div className="col-lg-7 mb-2">
                                <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                                    <span style={{ color: "rgb(17, 112, 150)" }}>Targeted Asthma Therapy</span>
                                </h1>
                                <p>
                                    Welcome to Shri Hospital's groundbreaking initiative, Aapudam Asthma, spearheaded by the visionary Dr. Bharani. Our dedicated team is committed to revolutionizing asthma treatment, improving patients' quality of life, and providing holistic care that transcends boundaries.
                                </p>
                                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                                    <span style={{ color: "rgb(52, 186, 240)" }}>About Aapudam Asthma:</span>
                                </h4 >
                                <p style={{ textAlign: 'justify' }}>
                                    Aapudam Asthma is a cutting-edge targeted asthma therapy program developed by Dr. Bharani, a renowned expert in the field of respiratory medicine. With a deep understanding of the challenges faced by asthma patients, Dr. Bharani's revolutionary approach focuses on personalized, precision-based treatment strategies.
                                </p>

                            </div>
                            <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                                <img className="mt-5" style={{ width: '80%', height: '300px', borderRadius: '15px' }} id="ServicesImg" src='apudham.png' alt=''></img>
                            </div>
                        </div>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}>Our Vision:</span>
                        </h4 >
                        <p>At Aapudam Asthma, we envision a world where asthma patients not only manage their symptoms but thrive in every aspect of life. Our goal is to empower individuals to regain control over their breath and lead fulfilling lives, free from the constraints of asthma.</p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}>Key Features:</span>
                        </h4 >
                        <p><b>Precision Treatment Plans:</b> We understand that asthma varies from person to person. Our expert team conducts comprehensive assessments to design customized treatment plans that address the unique needs of each patient.</p>
                        <p><b>Cutting-edge Technology:</b> Aapudam Asthma harnesses the power of advanced diagnostic tools to identify specific triggers, allergic reactions, and other contributing factors. This enables us to create highly targeted treatment strategies.</p>
                        <p><b>Holistic Approach:</b> Our approach extends beyond medication. We emphasize the importance of lifestyle modifications, stress reduction techniques, and nutritional guidance to optimize asthma management.</p>
                        <p><b>Education and Empowerment:</b> Knowledge is key to effective asthma management. We provide patients and their families with the information they need to understand asthma, recognize warning signs, and respond appropriately.</p>
                        <p><b>Patient-Centric Care:</b> Aapudam Asthma treats patients, not just symptoms. We cultivate a compassionate and supportive environment where patients feel heard, valued, and involved in their own care journey.</p>
                        <p><b>Research and Innovation:</b> Dr. Bharani and the Aapudam Asthma team are committed to advancing asthma treatment through ongoing research and innovation. Our dedication to staying at the forefront of medical advancements ensures that our patients receive the best care possible.</p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}>Why Choose Aapudam Asthma:</span>
                        </h4 >
                        <p><b>Expert Leadership:</b> Dr. Bharani's expertise and leadership in respiratory medicine are unparalleled, assuring patients that they are in the hands of a true visionary.</p>
                        <p><b>Personalized Care:</b> We recognize the uniqueness of each patient's condition and tailor treatment plans accordingly, leading to more effective outcomes.</p>
                        <p><b>Innovative Approach:</b> Aapudam Asthma's groundbreaking approach integrates the latest scientific insights and technologies to provide optimal results.</p>
                        <p><b>Empowerment:</b> Our program empowers patients to actively participate in their own health journey, fostering a sense of control and confidence.</p>
                        <p><b>Positive Impact:</b> Countless individuals have experienced improved asthma control, reduced symptoms, and enhanced quality of life through Aapudam Asthma.</p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(52, 186, 240)" }}>Join the Aapudam Asthma Community:</span>
                        </h4 >
                        <p>At Aapudam Asthma, we invite you to take a step toward a life without the limitations of asthma. Connect with us to learn more, schedule a consultation, and embark on a journey towards better breathing and a brighter future. Breathe freely with Aapudam Asthma!</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Targeted_Asthma_Therapy